@import 'src/styles/commons';

.room-item {
  .room-item-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;

    .title-description {
      display: flex;
      flex-direction: column;
      gap: 4px;

      > .room-description {
        color: rgb(140, 140, 140);
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }
    }
  }
  .room-item__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 40px;
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;

    img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-emoji {
      img {
        border-radius: 4px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .view-text {
    color: rgb(140, 140, 140);
    font-size: 14px;
    font-weight: normal;
  }

  .edit-link {
    a {
      color: rgb(191, 191, 191);
    }
  }

  li {
    span {
      color: rgb(191, 191, 191);
    }
  }

  .ant-select {
    width: 104px;
  }

  .ant-list-item-meta-content {
    align-self: center;

    .ant-list-item-meta-title {
      color: rgb(16, 16, 16);
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      line-height: 20px;
      margin-bottom: 0;
    }

    .ant-list-item-meta-description {
      color: rgb(140, 140, 140);
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
    }
  }
}
