@import 'src/styles/commons';

.rooms-form-container {
    display: flex;
    justify-content: center;

    .rooms-form {
        .section {
            width: 802px;
            border-radius: 8px;
            border: 1px solid rgba(0, 0, 0, 0.15);

            .section-header {
                padding: 12px 24px;

                .title {
                    color: #101010;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 32px;
                }
                .subtitle {
                    color: #8C8C8C;
                    font-size: 14px;
                    line-height: 20px;
                    margin-bottom: 4px;
                }
            }

            .section-body {
                border-top: 1px solid rgba(0, 0, 0, 0.15);
            }
        }
    }
}
