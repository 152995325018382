@import 'src/styles/commons';

.general {
  padding: 24px;

  .ant-row.ant-form-item-row {
    margin-bottom: 0;
    margin-left: 64px;

    .ant-form-item-label {
      margin-right: 16px;

      label {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .description {
    margin-left: 200px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    color: #8c8c8c;
    font-size: 14px;
    line-height: 20px;

    .anticon {
      margin: 3px 5px 3px 0;
    }
  }
}
