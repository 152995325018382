.editoEventMainInformation {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.editoEventMainInformation > .titleContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
}