@import 'src/styles/commons';

.hotel-detail {
  margin-left: 200px;
  background-color: #fff;

  .ant-form {
    height: 100%;

    .anticon {
      width: 16px;
      height: 16px;
    }
  }

  .hotel-detail__content {
    padding: 24px;
    flex: 1;
    overflow-y: auto;
  }

  .hotel-detail__empty {
    flex: 1;
  }

  .hotel-detail__last-card {
    margin-bottom: 0 !important;
  }
}

.duplicate-btn {
  .ant-btn {
    padding: 0px 16px;
    height: 32px;
    line-height: 24px;
    font-weight: 500;
  }
}

.admin_settings .ant-row.ant-form-item-row .ant-form-item-label {
  width: 20%;
  max-width: initial;
}
