.bar-step {
  .pricing-rule {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    width: 100%;

    .pricing-rule-choice {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      > label,
      > div {
        border: 1px solid #f0f0f0;
      }

      > label,
      .with-custom-form .radio {
        width: 100%;
        display: flex;
        padding: 16px;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        color: #262626;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .bar-form {
        display: flex;
        padding: 0 16px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;

        .form {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #262626;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;

          input {
            display: flex;
            width: 50px;
            align-items: flex-start;
            gap: 8px;
          }

          .ant-select {
            display: flex;
            width: 95px;
            flex-direction: column;
            align-items: center;
            gap: 8px;
          }
        }

        .info {
          color: #8c8c8c;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}
