@import 'src/styles/commons';

.ant-layout-header {
  h1 {
    margin: 20px 0;
    line-height: 1;
    font-size: 24px;
  }
}

.page__form-header {
  &.ant-layout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid $grey300;
    background: #fff;
    padding: 0 24px;
    width: 100%;

    h1 {
      flex-basis: 500px;
      padding-left: 24px;
    }

    .page__form-control {
      display: flex;
      flex-basis: 320px;
      align-items: center;
      justify-content: flex-end;

      button {
        width: 150px;
      }

      .page__form-message {
        width: 180px;
      }
    }
  }
}

.page__list-header {
  &.ant-layout-header {
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 0 24px;
    width: 100%;
    height: auto;

    .ant-menu {
      margin-top: -15px;
      border: 0;
    }
  }
  border-bottom: 1px solid #e0e0e0;
}

.page__content {
  position: relative;
  padding: 24px;
  max-width: 1310px;
}

.page__actions {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px;

  .ant-input-affix-wrapper {
    max-width: 250px;
  }
}

.page__actions-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.page__container {
  margin-left: 200px;
}

.page__body {
  overflow: initial;
  background-color: #fff;
}
