@import 'src/styles/commons';

.new-custom-tag-modal {
    margin-left: 200px;

    .ant-modal-body {
        max-height: 600px;
        overflow-y: auto;
    }
}
