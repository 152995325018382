@import 'src/styles/commons';

.notif-emails {
  .error-message {
    margin: 16px 32px;
    color: red;
  }

  .email-row {
    align-items: baseline;

    .delete-icon {
      margin-left: 12px;
      font-size: 16px;
      color: #bfbfbf;
      margin-top: 8px;
    }
  }

  .ant-form-item-control-input-content {
    button {
      border-style: dashed;
      width: 320px;
    }
  }

  .ant-row .ant-col .ant-form-item-label {
    margin-right: 16px;
    margin-left: 24px;
  }

  .ant-form-item-control-input {
    width: 320px;
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .with-delete {
    .ant-form-item-control-input {
      width: 346px;
    }
  }

  .ant-form-horizontal .ant-form-item-control {
    width: 320px;
  }
}

.formEmailPosition {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;

  .ant-form-item {
    margin-bottom: 0 !important;
  }
}
