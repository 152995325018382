@import 'src/styles/commons';

.extranet-inventory-discount-tooltip {
  .ant-tooltip-arrow {
    border-bottom-color: #101010;
  }

  .ant-tooltip-inner {
    background-color: #101010;
    color: #fff;
  }
}
