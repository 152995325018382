@import 'src/styles/commons';

.review-modal {
  margin-left: 200px;

  .ant-row.ant-form-item-row {
    margin-bottom: 0;
  }

  .input-row {
    display: flex;
    margin-bottom: 24px;

    &-no-rules {
      .ant-form-item-explain {
        display: none;
      }
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .ant-btn {
      margin-left: 8px;
      color: rgb(16, 16, 16);
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .ant-input {
        width: 439px;
      }

      .ant-form-item-label {
        margin-right: 16px;
        width: 50px;
        text-align: start;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label.ant-form-item-required::before {
          display: none;
        }

        label::after {
          display: none;
        }
      }
    }
  }
}
