.editoEventTable .ant-table .ant-table-title {
  padding: 0px 0px 24px 0px;
}

.editoEventTableLocationTr {
  background: #f9f9f9;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  width: 100%;
}

.editoEventTable .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: inherit;
}

.editoEventTable .ant-table-content {
  border-top: 1px solid #f0f0f0;
}

.editoEventTableLocationTr > .title {
  flex: 1 1 0;
  color: #999999;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 20px;
  letter-spacing: 0.6px;
  word-wrap: break-word;
}

.editoEventTable .ant-table {
  border: inherit;
  margin: 0px 24px;
}

.editoEventTable .ant-table-container {
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}

.editoEventTable .ant-table-thead > tr > th {
  padding: inherit;
}

.editoEventTable .ant-table-thead > tr {
  padding: 8px 16px;
}

.editoEventTable .editoPicture {
  width: 40px;
  height: 40px;

  img {
    width: 100%;
    height: 100%;
  }
}
