@import 'src/styles/commons';

.init-pwd-header {
  display: flex;
  width: 100%;
  border-bottom: 1px solid $grey300;
  padding: 17px 0;
  align-items: center;
  justify-content: center;
  background-color: #fff;

  .logo {
    width: 190px;
    height: 30px;
    color: #000;
  }
}

.init-pwd-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.form-card {
  display: flex;
  width: 414px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;

  .form-card__title {
    color: rgb(16, 16, 16);
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 32px;
  }

  .form-card__subtitle {
    width: 100%;
    color: rgb(140, 140, 140);
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 20px;
  }

  .ant-btn-primary {
    width: 100%;
    height: 40px;
    border-radius: 4px;
  }
}

.formItemContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .formItemContainer__title {
    width: 100%;
    height: 16px;
    color: rgb(16, 16, 16);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
  }

  .formItemContainer__input {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 4px;

    .ant-row {
      width: 100%;
    }
  }

  .formItemContainer__error {
    width: 100%;
    height: 16px;
    color: rgb(140, 140, 140);
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 16px;
  }
}
.form-card > * {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-card .formHeader {
  gap: 8px;
}
.form-card .formBody {
  gap: 24px;
}
.form-card .ant-form-item {
  margin-bottom: 0;
}
