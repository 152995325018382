.sub-title {
    color: #8C8C8C;
    font-size: 14px;
    font-weight: 400;
}

.formSectionTitle {
    font-size: 16px;
    font-weight: 600;
}

.content {
    margin-top: 16px
}

.quickFilterTranslation .ant-row.ant-form-item-row .ant-form-item-label {
    width: 100%;
}

.quickFilterSlug .ant-row.ant-form-item-row .ant-form-item-label {
    width: 100%;
}

.quickFilterTranslation .ant-row {
    flex-wrap: wrap;
}

.quickFilterSlug .ant-row {
    flex-wrap: wrap;
}

.quickFilterTranslation .ant-form-item-label > label.ant-form-item-required::before{
    content: none;
 }