.headerWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.headerWrapper > .titleChildren > .titleContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;

  .bold {
    font-size: 16px;
    font-weight: 600;
    line-height: 20.8px;
  }

  .normal {
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  .optional {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
}

.headerWrapper > .maxCharacters {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
}

.headerWrapper > .titleChildren {
  display: flex;
  flex-direction: column;
}

.headerWrapper > .titleChildren > .ant-form-item {
  margin: 0px;
}
