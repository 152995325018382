.column {
  display: flex;
  justify-content: center;
  text-align: center;
}

.headerContact {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  margin-bottom: 16px;
}
