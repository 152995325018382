.get-lucky {
  .sub-text {
    color: rgb(140, 140, 140);
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;

    .info-circle {
      margin-right: 4px;
    }
  }

  .selector {
    max-width: 440px;
  }
}

.get-lucky,
.get-lucky-select {
  .text {
    font-weight: bold;
  }

  .description {
    margin-left: 5px;
    font-size: 0.85em;
  }
}
