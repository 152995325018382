@import 'src/styles/commons';

.marketing-brief-form {
    height: 100%;

    .ant-empty {
        margin: 0 !important;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .scard-with-pictures {
        .staycation-card__content {
            padding: 18px !important;
        }
    }
}
