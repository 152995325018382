@import 'src/styles/commons';

.headline {
    .headline__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        background: rgb(255, 255, 255);
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;

        &-clickable {
            &:hover {
                cursor: pointer;
            }
        }

        span {
            width: 16px;
            height: 16px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .ant-list-item-meta-content {
        align-self: center;

        .ant-list-item-meta-title{
            color: rgb(16, 16, 16);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0px;
            line-height: 24px;
        }

        .ant-list-item-meta-description {
            .headline__description-view-container {
                display: flex;
                align-items: center;

                .headline__description-view {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: rgb(16, 16, 16);
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    line-height: 20px;
                    width: 48vw;

                    &-no-input {
                        line-height: 32px;
                    }
                }

                span {
                    margin-left: 12px;
                }
            }
        }
    }
}
