.package-modal-container .ant-modal-body {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
}

.package-detail-rule {
  display: flex;
  padding: 12px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid #f2f2f2;

  .rule-icon {
    .anticon {
      width: 20px;
      height: 20px;
    }
  }

  .rule-texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;

    .title {
      overflow: hidden;
      color: #1a1a1a;
      text-overflow: ellipsis;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      align-self: stretch;
    }

    .description {
      color: #808080;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;

      .bold {
        font-weight: 600;
      }
    }
  }

  .rule-check {
    display: flex;
    padding: 4px;
    align-items: center;
    gap: 10px;
    border-radius: 50%;
    background: #28a745;

    .anticon {
      width: 12px;
      height: 12px;
      color: white;
    }
  }

  .anticon svg {
    width: 100%;
    height: 100%;
  }
}

.package-detail-room-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;

  .detail-room-list {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .detail-room-list-bed {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .package-detail-room-type {
    color: #808080;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

  .package-detail-room {
    display: flex;
    padding: 12px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid #f2f2f2;

    .room-icon {
      display: flex;
      padding: 16px;
      align-items: center;
      gap: 10px;
      border-radius: 7px;
      background: #f9f9f9;
    }

    .room-texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;

      .room-title,
      .room-description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        align-self: stretch;
      }

      .room-title {
        overflow: hidden;
        color: #1a1a1a;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }

      .room-description {
        overflow: hidden;
        color: #808080;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
    }
  }
}
