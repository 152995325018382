.sider {
  position: fixed;
  left: 0;
  z-index: 10;
  background-color: #101010;
  height: 100vh;
  overflow: auto;

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu {
      margin-top: 40px;
      background-color: #101010;

      .standalone-item {
        opacity: 0.65;
        margin: 0;
        background-color: #101010;
        height: 48px;

        a {
          div {
            line-height: 48px;

            span.anticon {
              vertical-align: middle;
              font-size: 16px;
            }
          }
        }

        &:hover {
          opacity: 0.85;
        }
      }

      .standalone-item.ant-menu-item-selected {
        opacity: 1;
      }

      .submenu {
        background-color: #101010;

        .menu-item {
          background-color: #181818;
        }
      }
    }

    .selector {
      align-items: space;
      background-color: #181818;
      cursor: pointer;
      padding: 0 16px 0 24px;
      width: 200px;
      text-align: left;
      transition-property: background-color;
      transition-duration: 0.5s;

      .selector-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 0;
        color: #fff;

        .selector-title {
          padding-right: 16px;
        }
      }

      &:hover {
        background-color: #222;
      }
    }

    .selector-hotelier {
      align-items: space;
      margin-bottom: 40px;
      background-color: #181818;
      padding: 0 16px 0 24px;
      width: 200px;
      text-align: left;

      .selector-div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 13px 0;
        color: #fff;

        .selector-title {
          padding-right: 16px;
          min-height: 14px;
        }
      }
    }

    .title {
      padding-top: 24px;
      padding-left: 24px;
      width: 200px;
      height: 72px;
      letter-spacing: 0;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
    }

    .logo {
      margin: 24px 0 50px -35px; // TODO: because of bad svg, bad margin...
      width: 250px;
      color: #fff;
    }

    .bottom_admin {
      margin-top: 12px;
      margin-bottom: 12px;
      margin-left: 24px;
      height: 24px;
      letter-spacing: 0;
      color: #fff;
      font-size: 14px;
      font-weight: 500;

      .avatar {
        margin-right: 8px;
        width: 24px;
        height: 24px;
      }
    }

    .bottom_extranet {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-top: 1px solid rgba(255, 255, 255, 0.04);

      .avatar {
        margin-right: 8px;
        width: 28px;
        height: 28px;
      }

      .title-ac {
        color: rgba(255, 255, 255, 0.65);
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }

      .info-ac {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name-avatar-ac {
          display: flex;
          align-items: center;
          .name-ac {
            overflow: hidden;
            color: #fff;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            white-space: nowrap;
          }
          .avatar {
            margin-right: 8px;
            width: 28px;
            height: 28px;
          }
        }
        .email-ac {
          overflow: hidden;
          color: #fff;
          text-overflow: ellipsis;
          font-size: 11px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          cursor: pointer;
          white-space: nowrap;
        }
      }
    }
  }
}

.sider-hotel-popup {
  .ant-input-search {
    input {
      line-height: 24px;
      font-size: 16px;
    }

    .ant-input-suffix {
      line-height: 24px;

      .ant-input-search-icon {
        color: #bbb;
        font-size: 14px;
      }
    }
  }

  .ant-modal-close {
    margin: 24px;

    .ant-modal-close-x {
      width: 16px;
      height: 16px;
      line-height: 16px;
      color: #000;
    }
  }

  .ant-modal-body {
    padding: 0;
    max-height: 333px;
    overflow-y: scroll;

    .popup-section {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #f0f0f0;
      cursor: pointer;
      padding: 16px 24px;
      min-height: 48px;

      .section-text {
        padding-right: 16px;
        line-height: 16px;
      }

      .section-icon {
        line-height: 16px;
        font-size: 16px;
      }

      &:first-child {
        border-top: 0;
      }

      &:hover {
        background-color: #fafafa;
      }
    }
  }
}

// default page
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item {
  > a {
    color: rgba(255, 255, 255, 0.45);
  }
}

// Selection of a page
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  border-right: #ff2e63 solid 4px;
  background-color: #222;

  > a {
    color: #fff;
  }
}

// Hover of a page
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-active {
  background-color: #222;

  > a {
    color: rgba(255, 255, 255, 0.65);
  }
}

// Default section
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  background-color: #101010;
}

// Hover on section
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-active {
  background-color: #101010;

  div {
    color: rgba(255, 255, 255, 0.85);
  }
}

.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background-color: #101010;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #181818;
}
