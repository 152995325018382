.cool-to-know {
    height: 96px;
    background-color: #FAFAFA;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    flex-direction: row;

    .border {
        flex: none;
        width: 4px;
        height: 100%;
        background-color: #101010;
    }

    .content {
        padding: 16px 0;

        .title {
            margin-left: 13.75px;
            margin-bottom: 0.75px;
            color: #101010;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;
            display: flex;
            flex-direction: row;
            vertical-align: middle;

            .anticon {
                margin-top: 0.75px;
                margin-right: 8.75px;

                svg {
                    width: 22.5px;
                    height: 22.5px;
                }
            }
        }

        .text {
            margin-left: 45px;
            line-height: 20px;
        }
    }
}
