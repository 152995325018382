@import 'src/styles/commons';
.field-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  .field-label {
    width: 192px;
    margin-right: 16px;
    text-align: right;
  }

  .field-container {
    width: 440px;
  }
}
.ant-select-item-option-content {
  margin-right: 0 !important;
  display: block !important;
}

.select-item__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .select-item__thumbnail {
    flex-shrink: 0;
  }

  .select-item__name {
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
