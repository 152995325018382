.extraBedModal {
  display: flex;
  align-items: center;
  justify-content: center;
  .ant-modal-header {
    padding: 0;
    border-bottom: 0;
  }
  &__header {
    padding: 24px 16px;
    h1 {
      color: #262626;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .ant-modal-body {
    padding: 0 16px 24px 16px;
    max-height: 600px;
    overflow-y: auto;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    .subtitle {
      color: #8c8c8c;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 16px;
    }
    &_rooms {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &_room {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &_info {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;

          &_image {
            height: 38px;
            img {
              height: 100%;
              border-radius: 4px;
            }
          }

          &_text {
            .title {
              color: #262626;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
            .description {
              color: #8c8c8c;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
            }
          }
        }

        .ant-collapse {
          margin: 0;

          & > * {
            margin: 0;
          }
        }
      }
    }
  }
}
