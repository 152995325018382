.forced-checkbox .ant-checkbox-wrapper:hover .ant-checkbox-inner,
.forced-checkbox .ant-checkbox:hover .ant-checkbox-inner,
.forced-checkbox .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: rgb(82, 196, 26) !important;
  border-radius: 2px;
}

.forced-checkbox .ant-checkbox-disabled:hover .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}

.forced-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: rgb(82, 196, 26) !important;
  border-color: rgb(82, 196, 26) !important;
}

.forced-checkbox .ant-checkbox-checked::after {
  border-color: rgb(82, 196, 26) !important;
  border-radius: 2px;
}

.forced-checkbox .ant-checkbox-inner {
  border-radius: 2px;
}
