@import 'src/styles/commons';
h1,
h2,
h3,
p,
div {
  margin: 0;
}
.taskCenter {
  .ant-drawer-header-title {
    position: relative;
    .ant-drawer-close {
      position: absolute;
      right: 0;
      margin-right: 0;
      padding: 0;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1 {
      color: #262626;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 6px;
    }
  }
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__body {
    flex: 1 0 auto;

    &_explanation {
      padding: 32px 24px 24px 24px;
      background-color: #fafafa;
      display: flex;
      flex-direction: column;
      gap: 4px;
      h1 {
        color: #262626;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      p {
        color: #262626;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
    }
    &_task {
      padding: 24px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    &_todo,
    &_done {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .section_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .doneIcon {
          color: #8c8c8c;
          font-size: 12px;
        }

        p {
          color: #8c8c8c;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }
      .section_item {
        display: flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
        border-radius: 2px;
        border: 1px solid #f5f5f5;

        &_info {
          &_icon {
            font-size: 24px;
            color: #ff2e63;
          }

          &_text {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .title {
              color: #262626;
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            }
            .description {
              color: #262626;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
          &_progress {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
            .bar {
              width: 100px;
            }
            .score {
              color: #8c8c8c;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px;
            }
          }
        }
      }
    }
  }

  &__footer {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    border-top: 1px solid #f0f0f0;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
    background: #fff;
  }
}
