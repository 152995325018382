@import 'src/styles/commons';

.ant-divider-horizontal.ant-divider-with-text.dashboardTitleDivider {
  margin: 0;
}

.dashboardTitleDivider {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-align: left;
}

.titleLine {
  border-left: 3px solid $primary-color;
  border-radius: 20px;
  height: 12px;
}
