.extraBedCollapse {
  .extraBedPanel {
    border: 1px solid #d9d9d9;

    > .ant-collapse-header {
      padding: 16px !important;
      border-bottom: 1px solid #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;

      .panel-header-titles {
        h3 {
          font-size: 14px;
        }

        h3::first-letter {
          text-transform: capitalize;
        }
      }

      .panel-header {
        align-items: center;

        .panel__actions .ant-collapse-item {
          border: 0;
          margin: 0;
          .ant-collapse-item-control-input .ant-switch .anticon svg {
            color: #fff;
            width: initial;
            height: initial;
          }
        }
      }
    }
    .ant-collapse-content-box {
      padding: 24px 16px;
      .ant-collapse-item {
        border: 0;
        margin: 0;
      }
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
.extraBedCollapse.ant-collapse-borderless
  > .ant-collapse-item.ant-collapse-item-active:last-child {
  border-bottom: 1px solid #d9d9d9;
}

.extraBedCollapse.ant-collapse-borderless
  > .ant-collapse-item
  > .ant-collapse-content
  > .ant-collapse-content-box {
  padding-top: 24px;
}

.extraBedCollapse.ant-collapse
  > .ant-collapse-item
  .ant-collapse-header-collapsible-only
  .ant-collapse-header-text {
  flex: auto;
}

.formItem {
  margin-bottom: 24px;
}

.bedTitleSection {
  color: #808080;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-transform: uppercase;
  margin-bottom: 16px;
}

.optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
}

.optionItem .optionBedName {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1a1a1a;
}
.optionItem .optionBedName.selected {
  color: #ff2e63;
}

.optionItem .optionBedCapacity {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #808080;
}

.bedType {
  display: flex;
  align-items: center;
  gap: 80px;
  padding-left: 26px;
}

.bedCapacity,
.bedCost,
.bedPrice {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-left: 26px;
}

.bedTypeTitle,
.bedCapacityTitle {
  color: #1a1a1a;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.bedCapacityTitle,
.bedCostTitle,
.bedPriceTitle {
  width: 84px;
}

.bedTypeForm,
.bedTypeForm.ant-select {
  display: flex;
  width: 331px;
  align-items: center;
  gap: 4px;
}

.bedCapacityDescription {
  display: flex;
  align-items: center;
  gap: 8px;
}

.bedTypeForm .ant-form-item,
.bedPriceForm .ant-form-item {
  margin-bottom: 0;
}
