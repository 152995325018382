@import 'src/styles/commons';

.hotel-score {
  &__percent {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 2px;
    &__100 {
      font-size: 12px;
      color: #808080;
    }
  }

  &__title {
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: bold;
    &__tag {
      margin-left: 8px;
      padding: 1px 8px;
      border-radius: 999px;
    }
    &__container {
      margin-left: 16px;
      align-self: center;
    }
  }

  &__subtitle {
    color: #808080;
  }
  &__content {
    margin-top: 24px;
    .ant-collapse-item {
      border: none;
    }
    .ant-collapse-content {
      padding: 0 24px 24px;
    }
    &.ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header {
      padding: 24px;
    }
    .ant-collapse-content-box {
      padding: 0;
    }
    .ant-progress-circle-trail {
      stroke: #e5e5e5;
    }
    &.ant-collapse-icon-position-end
      > .ant-collapse-item
      > .ant-collapse-header
      .ant-collapse-arrow {
      transform: translateY(-50%) translateX(-10px);
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: 'center';
  }

  &__checkcircle {
    color: #28a745;
    font-size: 20px;
  }

  &__task-button {
    margin-top: 16px;
    background-color: #f9f9f9;
    justify-self: flex-end;

    &.ant-btn.ant-btn-default {
      border: none;
      &:active {
        background-color: #e5e5e5;
      }
    }
  }

  &__card {
    width: 380px;
    .ant-card-actions {
      border: none;
    }
    .ant-card-meta-description {
      height: 46px;
    }
    .ant-card-body {
      padding-bottom: 16px;
    }
  }
}
