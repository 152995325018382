@import 'src/styles/commons';

.selectable-image {
    position: relative;
    background-color: #EEE;
    cursor: pointer;
    overflow: hidden;

    img {
        transition: transform 0.135s cubic-bezier(0, 0, 0.2, 1), opacity linear 0.15s;
    }

    &.selectable-image--selected {
        img {
            transform: translateZ(0) scale3d(0.9, 0.9, 1);
            transition: transform 0.135s cubic-bezier(0, 0, 0.2, 1), opacity linear 0.15s;
        }
    }

    &:not(.selectable-image--selected) {
        &:hover {
            outline: 2px solid fade($primary-color, 30%);
        }
    }
}
