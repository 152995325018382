@import 'src/styles/commons';


.sortable-panel {
    .ant-collapse-header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .ant-collapse-content {
        padding: 16px 40px !important;
    }

    .panel__header {
        width: 100%;
    }

    .panel__actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 40px;

        .anticon {
            cursor: pointer;
            padding: 0 8px;
            color: $grey500;
            font-size: 18px;
        }
    }
}
