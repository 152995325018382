@import 'src/styles/commons';

.automatedDiscountModal {
  max-height: 90vh;

  .divider {
    color: #f0f0f0;
  }

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .ant-modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      position: relative;
      flex-grow: 1;
      height: 100%;
    }
  }

  .automatedDiscountModalTitle {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &_subtitle {
      color: #8c8c8c;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  &__modal_wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;

    &__automation_explanation_collapse.ant-collapse {
      margin: 0;
      .explanation_panel {
        margin-top: 0;
        border: 1px solid #f0f0f0;
        > .ant-collapse-header {
          padding: 16px !important;
          display: flex;
          align-items: center;
          justify-content: center;

          .explanation_header {
            display: flex;
            align-items: center;

            .anticon {
              font-size: 24px;
              margin-right: 16px;
            }
            h3 {
              font-size: 14px;
              color: #262626;
              font-style: normal;
              font-weight: 600;
              line-height: 22px;
            }
          }
        }

        .description_list {
          list-style: none;
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0;
          gap: 4px;

          &__item {
            color: #262626;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            &--bold {
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    padding: 10px 24px;

    .automatedDiscountModalFooter {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &__left {
        display: flex;
        align-items: center;

        &__remove_rule {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 16px;
          padding: 0;

          .ant-btn {
            padding: 0;
          }
        }
      }
      &__right {
        display: flex;
        align-items: center;
        .ant-form-item {
          margin: 0;
        }
      }
    }
  }
}

.automate-discount-confirmation-popin {
  width: 300px;

  p {
    line-height: 22px;
    font-size: 14px;
  }

  .popin-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
    .bold-text {
      font-weight: 600;
    }
  }

  .popin-cta {
    margin-top: 12px;
    text-align: right;
    .popin-no {
      margin-right: 8px;
      height: 24px;
    }

    .popin-yes {
      height: 24px;
    }
  }
}
