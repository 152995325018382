@import 'src/styles/commons';

.refunds {
  .ant-collapse {
    margin: 0;
    border: 0;

    .ant-collapse-item {
      margin-top: 0;
      overflow: hidden;
      border: 0;

      .ant-collapse-header {
        padding: 16px 48px !important;
        color: #101010;
        font-size: 14px;
        font-weight: 500;

        .bed-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            margin-right: -30px;

            svg {
              color: #bfbfbf;
              width: 16px;
              height: 16px;
            }
          }
        }

        .anticon {
          svg {
            color: #101010;
            width: 15px;
            height: 15px;
          }
        }
      }

      .ant-collapse-content {
        border: 0;

        .ant-collapse-content-box {
          padding: 0;
        }
      }
    }
  }
}
