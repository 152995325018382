.searchCriteriasContainer {
  display: flex;
  flex-direction: row;
  gap: 24px;

  .searchCriteriasSubContainer {
    flex: 1;

    .criteriaValueContainer {
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: flex-end;
      position: relative;
    }

    .deleteCriteriaButton {
      margin-top: 30px;
    }
  }
}

.addCriteriaButtonContainer {
  margin-top: 8px;
}
