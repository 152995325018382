@import 'src/styles/commons';

.bills-list {
    margin-left: 200px;
    background-color: #FFF;
    width: 100%;

    .ant-layout {
        background-color: #FFF;
    }

    .header {
        position: sticky;
        top: 0;
        z-index: 20;
        border-bottom: 1px solid #E0E0E0;
        background-color: #FFF;
        width: 100%;

        .ant-page-header-heading-left {
            margin: 0px;
        }
    }

    .body {
        position: relative;
        margin: 24px 24px 0 24px;
        max-width: 1210px;

        .body-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;
                align-items: center;
            }

            .right {
                display: flex;
                align-items: flex-end;
            }

            .search {
                width: 280px;
                margin-right: 16px;
                height: 32px;
            }

            .ant-input-suffix {
                line-height: initial;
                color: #BBB;
            }

            .button {
                margin-right: 8px;
                margin-left: 16px;
                height: 32px;
            }

            .button-wrapper {
                margin-left: 16px;
            }
        }

        .ant-table {
            overflow: hidden;
        }

        .ant-table-title,
        .ant-table-container {
            overflow-y: scroll;

            &::-webkit-scrollbar {
                height: 6px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #888;
            }

            &::-webkit-scrollbar:vertical {
                display: none;
            }
        }

        .ant-table-thead > tr > th {
            border-top: 1px solid #D9D9D9;
            border-bottom: 1px solid #D9D9D9;
            padding-top: 0;
            padding-bottom: 0;
            height: 40px;

            .ant-table-column-sorters-with-tooltip {
                min-width: 111px;
            }
        }

        .ant-table-tbody {
            tr:last-child {
                td {
                    border-bottom: none;
                }
            }
        }

        th.sort-column {
            display: flex;
            align-items: center;
            background-color: #F8F8F8;
            cursor: pointer;
            line-height: 38px;

            &:hover {
                background-color: #F0F0F0;
            }
        }

        .sort-button {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: flex-start;
            border-width: 0;
            border-radius: 4px;
            padding: 8px 16px;
            width: 100%;
            height: 100%;
            font-weight: 500;
            transition-duration: 0.4s;

            &:hover {
                background-color: #F0F0F0;
            }
        }

        .actions-menu {
            display: flex;
            justify-content: space-around;
            font-size: 16px;

            .anticon {
                padding: 4px;
                color: $grey500;
            }
        }

        .footer {
            padding: 16px 0;
        }

        .button-wrapper,
        .footer {
            display: flex;
            justify-content: flex-end;
            min-width: 106px;

            [ant-click-animating-without-extra-node]::after {
                animation: none !important;
                -webkit-animation: none !important;
                -moz-animation: none !important;
                -ms-animation: none !important;
            }

            button {
                margin-left: 5px;
                width: 32px;

                &:first-child {
                    margin-left: 0;
                }

                &:hover,
                &:active,
                &:focus {
                    border-color: #D9D9D9;
                    color: #2D2D2D;
                }
            }
        }
    }
}
