.dataCard_container {
  width: 100%;
  padding: 16px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
}

.dataCard_title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataCard_title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #1a1a1a;
}
.dataCard_data {
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.dataCard_number_container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.dataCard_number {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 1px;
  text-align: left;
  color: #1a1a1a;
}

.dataCard_period_comparaison {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #b3b3b3;
}
.dataCard_description {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.dataCard_description_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: #b3b3b3;
}
.dataCard_description_text .success {
  color: #28a745;
}
.dataCard_description_text .danger {
  color: #ff382c;
}
.dataCard_description_text .neutral {
  color: #8c8c8c;
}

.popoverDataCard .ant-popover-arrow {
  display: none;
}

.popoverDataCard .ant-popover-inner-content {
  padding: 16px;
}

.popoverDataCard .ant-popover-inner {
  border-radius: 8px;
}
