@import 'src/styles/commons';

.detail-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    .detail-layout__header {
        z-index: 5;
        background: #FFF;
        color: #FFF;
        border-bottom: #E0E0E0 solid 1px;
        width: 100%;
        padding: 16px 24px;

        .ant-page-header-heading {
            height: 32px;
            margin: 0px;
        }

        .ant-page-header-heading-extra {
            margin: 0px;
            display: flex;
        }

        .ant-page-header-content {
            padding-top: 0px;
        }

        &-menu {
            padding: 16px 24px 0px 24px;

            .ant-menu-horizontal {
                border-bottom: 0px;
            }
        }
    }

    .detail-layout__footer {
        display: flex;
        z-index: 5;
        justify-content: flex-end;
        background: #FFF;
        border-top: #E0E0E0 solid 1px;
        padding: 16px 24px;
        width: 100%;

        .ant-btn {
            margin-left: 16px;
            padding: 0px 16px;
            height: 32px;
            line-height: 24px;
            font-weight: 500;
        }
    }
}
