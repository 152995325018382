.content {
  overflow-y: auto;
  padding:0 40px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  background: #FFF;
  border-top: #E0E0E0 solid 1px;
  padding: 16px 24px;
  width: 100%;
}
