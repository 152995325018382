@import 'src/styles/commons';

.list-experiences {
    display: grid;

    .list-experiences__add-button{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        width: 100%;
        height: 64px;
        font-weight: normal;
        margin-top: 24px;

        &:hover {
            border-color: #FF2E63;
        }

        .anticon svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
        }
    }
}
