.input-textarea {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    label {
        font-weight: 500;
        font-size: 14px;
        width: 192px;
        margin-right: 28px;
        text-align: right;
    }

    textarea, div {
        width: 440px;
    }
}
