@import 'src/styles/commons';

.channel-manager-block {

    .ant-form-item-label {
        margin-right: 16px;
        margin-left: 24px;
    }


    .ant-form-item-control-input {
        width: 200px;
    }

    .channel-manage-label-view {
        margin-right: 26px;
    }
}
