@import 'src/styles/commons';

.room-categories {
    .categories {
        .ant-list-item {
            padding: 12px 24px;
            border-color: rgba(0, 0, 0, 0.15);

            .avatar {
                .twic.pic {
                    border-radius: 4px;
                    height: 40px;
                    width: 56px;
                }
            }

            .ant-list-item-meta-content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 40px;

                .ant-list-item-meta-title {
                    margin-top: 2px;
                    margin-bottom: 0px;
                    line-height: 16px;
                    font-weight: normal;
                    color: #101010;
                }

                .ant-list-item-meta-description {
                    margin-top: 4px;
                    line-height: 16px;
                    margin-bottom: 2px;
                    color: #8C8C8C;
                }
            }

            .ant-list-item-action-split {
                display: none;
            }
        }
    }

    .footer {
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content: center;
        padding: 16px 24px;

        button {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }
}
