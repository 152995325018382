@import 'src/styles/commons';

.admin-modal {
  margin-left: 200px;
}

.refund-modal {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-close-x {
      width: 65px;
      height: 65px;
      line-height: 65px;

      .anticon {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .ant-modal-header {
      border-color: rgba(0, 0, 0, 0.15);

      .ant-modal-title {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
      }
    }

    .ant-modal-body {
      overflow-y: scroll;
      max-height: 522px;

      div:last-child {
        .block {
          margin-bottom: 16px;
        }
      }
    }

    .ant-modal-footer {
      border-color: rgba(0, 0, 0, 0.15);
      padding: 12px 24px;

      button {
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        &:disabled {
          background-color: #fff;

          span {
            color: #bfbfbf;
          }
        }
        &:last-child {
          margin-left: 16px;
        }
      }
    }

    .input-row {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0px;
      }

      .ant-row.ant-form-item-row {
        margin-bottom: 0;

        .ant-select {
          border-color: rgba(0, 0, 0, 0.15);
        }

        .ant-input {
          border-color: rgba(0, 0, 0, 0.15);
        }

        .ant-form-item-label {
          text-align: left;

          label {
            font-weight: 500;
            font-size: 14px;
          }

          label.ant-form-item-required::before {
            display: none;
          }
        }

        .ant-form-text {
          color: lightgray;
        }
      }
    }
  }
}
