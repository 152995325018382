.extraAddOnModal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-content {
    overflow: hidden;
    max-height: calc(100vh - 2 * 28px);
    display: flex;
    flex-direction: column;
  }

  .ant-form-item {
    margin-bottom: inherit;
  }

  .ant-modal-header {
    padding: 0;
    border-bottom: 0;
  }
  &__header {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #f0f0f0;
    h1 {
      color: #262626;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .ant-modal-body {
    overflow-y: auto;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button.ant-btn.saveBtn {
      margin-left: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h1 {
      color: #1a1a1a;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    .bodySection {
      display: flex;
      gap: 24px;

      .cost .input .ant-input-number {
        width: 100%;
      }

      .cost .extra-cost-tooltip {
        margin-left: 4px;
        color: #8c8c8c;
      }

      .cost .input,
      &.extraSection .input {
        display: flex;
        flex-direction: row;

        .ant-input-number {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: none;
        }

        .ant-select {
          width: inherit;

          .ant-select-selector {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }

      &.extraSection {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;

        &.disabled {
          border: 1px solid #f0f0f0;
          background: #fafafa;

          .sectionTitle .titleSubtitle *,
          .sectionText {
            color: #8c8c8c;
          }
        }

        .sectionTitle {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }

        .sectionText {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }

      .titleSubtitle {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .title {
        color: #262626;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
      }

      .subTitle {
        color: #8c8c8c;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }

      &.timeCost {
        flex-direction: row;

        > div {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }

      .selectDaysAvailable {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;

        .weekdaySwitch {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 16px;

          .day {
            color: #262626;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }
  }
}
