.dashboardSection {
  display: flex;
  flex-direction: column;
  gap: 16px;
  scroll-margin-top: 135px;
}

.cards {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}
