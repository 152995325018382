@import 'src/styles/commons';

.quickFilterLayout {
  margin-left: 200px;
  flex: 1;
}

.quickFilterLayout .ant-form-item {
  margin-bottom: 0px;
}

.quickFilterLayout .ant-divider-horizontal {
  margin: 0px;
}

.form-container {
  border-radius: 8px;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 36px;
  min-height: 100%;
  border: 1px solid #e5e5e5;
}

.preview-button {
  font-weight: 600;
}

.content-container {
  padding: 24px;
  height: 100%;
  background-color: $grey100;
  overflow-y: scroll;
}

.layout {
  margin-left: 200px;
  flex: 1;
}