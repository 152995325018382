@import 'src/styles/commons';

.guideListTabs .ant-tabs-nav {
  background-color: white;
  padding: 0 24px;
  margin: 0;
}

.guideTable .ant-table {
  border: inherit;
  padding: 20px 24px;
}
