.extranet-contact {
  margin-left: 200px;
  background-color: #fff;

  .container {
    flex: 1;
    overflow-x: auto;
    padding: 24px;
  }

  .ant-form {
    height: 100%;
    width: 100%;
    overflow-y: auto;

    .anticon {
      width: 16px;
      height: 16px;
    }
  }

  .container-collapse {
    padding-bottom: 32px;
  }
}
