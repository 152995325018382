@import 'src/styles/commons';

.input-container {
    margin-top: 24px;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    > label {
        font-weight: 400;
    }
}

.select-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
}

.hotel-alert {
    margin: 8px 0;
    border-radius: 4px;
    border: 1px solid var(--color-gray-100, #E5E5E5);
    background: var(--color-gray-25, #F9F9F9);

    .anticon {
        color: grey;
    }
}

.quantity-segment {
    margin-top: 24px;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--color-gray-25, #F9F9F9);

    .quantity-span {
        color: var(--color-gray-900, var(--text-primary, #1A1A1A));
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }

    .added-quantity {
        color: var(--color-primary-500, #FF2E63);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
}
