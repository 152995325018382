.activate-pause-modal {
  .ant-modal-header {
    padding: 16px;

    .header-modal {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      flex: 1 0 0;
      align-self: stretch;

      .title {
        color: #262626;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }

      .subtitle {
        color: #1a1a1a;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .ant-modal-body {
    padding: 24px 16px;

    .pause-content {
      display: flex;
      width: 196px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;

      .text {
        color: #1a1a1a;
        font-size: 14px;
        font-style: normal;
        line-height: 130%;
      }
    }

    .resume-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;

      .stocks-values {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;

        .text {
          color: #1a1a1a;
          font-size: 14px;
          font-style: normal;
          line-height: 130%;

          span {
            font-weight: 400;
          }
        }

        .description {
          color: #808080;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
        }

        .input {
          display: flex;
          max-width: 196px;
          width: 196px;
          padding: 5px 12px;
          align-items: center;
          gap: 10px;
          align-self: stretch;

          .ant-input-number-input {
            padding: 0;
          }
        }
      }
    }
  }

  .ant-modal-footer {
    .close-button {
      color: #262626;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    .confirm-button {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }
}
