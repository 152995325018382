@import 'src/styles/commons';

.upload-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    .upload-list__uploading-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin: 6px;
        width: 114px;
        height: 114px;

        span {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .upload-list__new-item {
        div {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px dashed rgba(0, 0, 0, 0.15);
            margin: 6px;
            width: 114px;
            height: 114px;

            &:hover {
                border-color: #FF2E63;
            }

            .anticon svg {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }

    .upload-list__empty-list {
        display: flex;
        width: 100%;
        justify-content: center;
    }
}

.upload-list-modal {
    margin-left: 200px;

    .thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 4px;
        width: 472px;
        height: 472px;
        overflow: hidden;

        img {
            width: 472px;
        }
    }
}
