@import 'src/styles/commons';

.guide-sections {
    margin-bottom: 24px;

    .guide-sections__panel {
        &.ant-collapse-item {
            & > .ant-collapse-header {
                padding: 50px 40px !important;

                h2 {
                    margin: 0 0 0 10px;
                }

                & > .arrow {
                    font-size: 15px !important;
                }
            }

            & > .ant-collapse-content-active {
                display: flex;
                justify-content: center;

                & > .ant-collapse-content-box {
                    @include big-screen {
                        padding-right: 50px;
                    }

                    padding: 0;
                    width: 100%;
                    max-width: 900px;
                }
            }
        }
    }

    .guide-sections__add {
        display: flex;
        justify-content: flex-end;
    }
}
