.experiences-modal {
    .ant-modal-content {
        border-radius: 8px;

        .ant-modal-close-x {
            width: 65px;
            height: 65px;
            line-height: 65px;

            .anticon {
                svg {
                    width: 15px;
                    height: 15px;
                }
            }
        }

        .ant-modal-header {
            border-color: rgba(0, 0, 0, 0.15);

            .ant-modal-title {
                font-size: 18px;
                line-height: 32px;
                font-weight: 600;
            }
        }

        .ant-modal-body {
            overflow-y: scroll;
            max-height: 522px;

            div:last-child {
                .block {
                    margin-bottom: 16px;
                }
            }
        }

        .ant-modal-footer {
            border-color: rgba(0, 0, 0, 0.15);
            padding: 12px 24px;

            button {
                span {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }
                &:disabled {
                    background-color: #FFF;

                    span {
                        color: #BFBFBF;
                    }
                }
                &:last-child {
                    margin-left: 16px;
                }
            }
        }
    }

    .cool-to-know {
        margin-top: 16px;
    }
}

.experiences-form-list {
    max-width: 800px;
    margin: 0 auto;

    .staycation-card__content {
        padding: 0;

        .experiences-form-list-item {
            display: flex;
            align-items: space-between;

            .fill-experience-button {
                width: 55px;
                height: 24px;
                line-height: 24px;

                font-size: 16px;
                font-weight: 500;
            }

            .ant-badge-status-dot {
                width: 8px;
                height: 8px;
    
                margin-right: 16px;
            }
    
            .ant-badge-status-text {
                margin: 0;
            }
        }
    }
}