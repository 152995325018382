.experiences-builder-form {
  .form-part {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 40px;

    .ant-row.ant-form-item-row {
      margin-bottom: 0;
    }

    .ant-row.ant-form-item-row {
      .ant-form-item-label {
        margin-right: 16px;
        width: 192px;
        max-width: unset;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label::after {
          display: none;
        }
      }

      .ant-radio-disabled + span {
        color: rgb(16, 16, 16) !important;
      }

      .ant-select {
        width: 320px;
      }

      .FIELD {
        display: flex;

        .ant-select {
          width: 160px !important;
        }
      }
    }

    .price-kind {
      .ant-input-number {
        width: 104px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
      }

      .ant-select {
        width: 156px;
        overflow: hidden;

        .ant-select-selector {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          background: #fafafa;
        }
      }
    }

    .not-price-kind {
      .ant-input-number {
        width: 104px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;

        .ant-input-number-input-wrap {
          &::after {
            top: 8px;
            right: 12px;
            line-height: 16px;
            position: absolute;
            color: #bfbfbf;
            font-size: 14px;
          }
        }
      }

      .ant-select {
        width: 156px;
        overflow: hidden;

        .ant-select-selector {
          border-left: none;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          background: #fafafa;
        }
      }
    }

    .ant-form-item-subfield {
      margin-bottom: 8px !important;
    }

    .input-row {
      margin-bottom: 24px;

      &-hotel-select {
        .ant-select {
          width: 440px !important;
        }
      }

      .ant-row.ant-form-item-row {
        margin-bottom: 0;
      }

      &:last-child {
        margin-bottom: 0px;
      }

      .ant-row.ant-form-item-row {
        margin-bottom: 0;

        .ant-cascader-picker {
          width: 440px;
        }

        .ant-picker {
          width: 200px;
        }

        .ant-select {
          width: 320px;
          border-color: rgba(0, 0, 0, 0.15);
        }

        .ant-input {
          width: 440px;
          border-color: rgba(0, 0, 0, 0.15);
        }

        .ant-input.ant-cascader-input {
          width: 100%;
        }

        .ant-input-number {
          width: 104px;
          border-color: rgba(0, 0, 0, 0.15);
        }

        .ant-form-item-label {
          margin-right: 16px;
          width: 192px;
          max-width: unset;

          label {
            font-weight: 500;
            font-size: 14px;
          }

          label.ant-form-item-required::before {
            display: none;
          }

          label::after {
            display: none;
          }
        }

        .view-text {
          white-space: pre-wrap;
          width: 416px;
        }

        .view-text__area {
          white-space: pre-wrap;
          width: 416px;
          margin-top: 4px;
        }
      }

      .experience__avatar-container {
        display: flex;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        width: 64px;
        height: 64px;

        .experience__avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          margin: 3px;
          background: rgb(255, 255, 255);
          border-radius: 4px;

          img {
            width: 50px;
            height: 50px;
          }
        }

        .experience__avatar__hover-area {
          opacity: 0;
          position: absolute;
          border-radius: 4px;
          margin: 3px;
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.48);
          }

          .upload-item__hover-buttons {
            .anticon {
              cursor: pointer;
              width: 16px;
              height: 16px;
              margin: 0 4px;
              color: #fff;
            }
          }
        }
      }
    }

    .description {
      margin-left: 208px;
      margin-top: 4px;
      display: flex;
      flex-direction: row;
      vertical-align: middle;
      color: #8c8c8c;
      font-size: 14px;
      line-height: 20px;

      .anticon {
        margin: 3px 5px 3px 0;
      }
    }

    .opening-hours {
      .input-row {
        .ant-row.ant-form-item-row {
          .ant-form-item-label {
            label.ant-form-item-required::before {
              display: none;
            }

            label::after {
              display: none;
            }
          }
        }
      }
    }

    .last-card {
      margin-bottom: 0 !important;
    }

    .ant-select-disabled {
      .ant-select-selector {
        border: none;
        background: none;
        color: #2d2d2d;
      }
    }
  }
}
