.auto-inventory-modal {
  max-height: 90vh;

  .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: 90vh;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      overflow-y: hidden;
      position: relative;
      flex-grow: 1;
      height: 100%;
    }
  }

  &.explication-part {
    .ant-modal-header {
      border-bottom: 0;
    }
    .ant-modal-header,
    .ant-modal-body,
    .ant-modal-footer {
      padding: 0;
    }
    .ant-modal-footer {
      border-top: 0;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    .disconnectButton {
      color: #ff382c;

      &:hover,
      &:focus,
      &:active {
        color: #ff382c;
      }
    }

    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    p {
      color: #808080;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
}
.alert-disconnect {
  .alert-footer {
    display: flex;
    padding-top: 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;

    .disconnect-button {
      color: #ff382c;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;

      &:hover,
      &:focus,
      &:active {
        color: #ff382c;
      }
    }
  }
}
