.container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.sectionContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;

  > :first-child {
    width: 150px;
  }

  .inputContent {
    margin-top: 16px;
    .input-value {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .combine-input {
        display: flex;

        > :first-child {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }

      .combine-input .ant-form-item,
      .input-value .ant-form-item {
        margin-bottom: 0;
      }

      .combine-input
        .ant-select:not(.ant-select-customize-input)
        .ant-select-selector {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 0;
      }

      .combine-input .ant-select-disabled .ant-select-arrow {
        display: none;
      }
    }

    .disclaimer {
      margin-top: 12px;
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
}
