@import 'src/styles/commons';

.room-modal-builder {
  .ant-modal-content {
    border-radius: 8px;

    .ant-modal-close-x {
      width: 65px;
      height: 65px;
      line-height: 65px;

      .anticon {
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }

    .ant-modal-header {
      border-color: rgba(0, 0, 0, 0.15);

      .ant-modal-title {
        font-size: 18px;
        line-height: 32px;
        font-weight: 600;
      }
    }

    .ant-modal-body {
      overflow-y: scroll;
      max-height: 522px;

      div:last-child {
        .block {
          margin-bottom: 16px;
        }
      }
    }

    .ant-modal-footer {
      border-color: rgba(0, 0, 0, 0.15);
      padding: 12px 24px;

      button {
        span {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
        &:disabled {
          background-color: #fff;

          span {
            color: #bfbfbf;
          }
        }
        &:last-child {
          margin-left: 16px;
        }
      }
    }
  }

  .title {
    color: #101010;
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .block {
    margin-bottom: 40px;
  }

  .input-row {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .ant-select {
        width: 320px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input {
        width: 440px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input-number {
        width: 104px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-form-item-label {
        margin-right: 16px;
        width: 192px;
        max-width: unset;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label.ant-form-item-required::before {
          display: none;
        }

        label::after {
          display: none;
        }
      }
    }
  }

  .description {
    margin-left: 208px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    color: #8c8c8c;
    font-size: 14px;
    line-height: 20px;

    .anticon {
      margin: 3px 5px 3px 0;
    }
  }

  .pictures-content {
    .upload-list {
      justify-content: space-evenly;
    }
  }

  .cool-to-know {
    height: 74px;
  }
}
