@import 'src/styles/commons';

.cover-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    .cover-cell__thumbnail {
        margin-right: 20px;
        border-radius: 4px;
        width: 50px;
        height: 40px;
        overflow: hidden;

        img {
            width: 50px;
            height: 40px;
            object-fit: cover;
        }
    }
}

.cover-cell-emoji {
    display: flex;
    flex-direction: row;
    align-items: center;

    .cover-cell__thumbnail {
        margin-right: 20px;
        border-radius: 4px;
        width: 50px;
        height: 40px;
        overflow: hidden;

        img {
            width: 40px;
            height: 40px;
            object-fit: cover;
        }
    }
}

.cover-cell-room {
    display: flex;
    flex-direction: row;
    align-items: center;

    .cover-cell-room__text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .cover-cell-room__title {
        line-height: 20px;
    }

    .cover-cell-room__subtitle {
        line-height: 20px;
        color: #8C8C8C;
    }

    .cover-cell-room__thumbnail {
        margin-right: 16px;
        border-radius: 4px;
        width: 56px;
        height: 40px;
        overflow: hidden;

        img {
            width: 56px;
            height: 40px;
            object-fit: cover;
        }

        &-emoji {
            img {
                border-radius: 4px;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.percent-cell {
    &--alert {
        color: $danger;
        font-weight: 500;
    }
}
