.extranetDashboard {
  margin-left: 200px;
  width: 100%;
}
.mainDashboard {
  position: relative;
  overflow: auto;
}

.dataContainer {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-color: #f6f6f6;
  position: relative;
}

.cardsContainer {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  width: 100%;
}

.cardsContainer.cardsWithTaskcenter {
  width: 50%;
}

.charts {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;
}

.loading {
  background-color: #ffffff;
  opacity: 80%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 24px;
  left: 24px;
  bottom: 24px;
  top: 24px;
  z-index: 1;
}
