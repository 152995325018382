@import 'src/styles/commons';

.hotel-builder {
    margin-left: 200px;
    background-color: #FFF;
    width: 100%;
    font-size: 16px;

    .ant-form-item-label {
        width: 150px;

        label.ant-form-item-required::before {
            display: none;
        }

        label::after {
            display: none;
        }
    }

    .anticon:not(.icon) {
        svg {
            font-size: 14px;
        }
    }
}

.hotel-builder-modal {
    margin-left: 200px;
}
