.extranet-inventory {
  &-tooltip {
    background-color: #fff;
    color: #101010;
    font-size: 12px;
    line-height: 18px;

    &__title {
      color: #101010;
    }

    &__subtitle {
      margin-top: 8px;
      color: #9c9c9c;
    }

    .ant-tooltip-arrow,
    .ant-tooltip-inner {
      background-color: transparent;

      &::before {
        background-color: #fff;
      }
    }

    .ant-tooltip-arrow {
      left: 0px !important;
    }
    &.bulkedit {
      &.ant-tooltip.ant-tooltip-placement-topLeft {
        width: 300px;

        .ant-tooltip-content {
          width: 300px;

          .ant-tooltip-inner {
            width: 100%;
            padding: 0;

            .extranet-inventory-tooltip__container {
              width: 100%;
              background: #fff;
              padding: 12px;
            }
          }
        }
      }
    }
    &.ant-tooltip.ant-tooltip-placement-topLeft {
      width: 400px;

      .ant-tooltip-content {
        width: 400px;

        .ant-tooltip-inner {
          width: 100%;
          padding: 0;

          .extranet-inventory-tooltip__container {
            width: 100%;
            background: #fff;
            padding: 12px;
          }
        }
      }
    }
  }
}
