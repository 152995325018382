
.alertSlugContainer {
    border-radius: 4px;
    background-color: #f5f5f5;
    padding: 16px;
    display: flex;
    gap: 10px;
    align-items: baseline;

    .textContainer {
      display: flex;
      flex-direction: column;
    }

    .icon {
      color: #8c8c8c;
      font-size: 16px;
    }

    .title {
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }

    .subTitle {
      color: #8c8c8c;
      font-size: 14px;
      font-style: italic;
      font-weight: 500;
      line-height: 22px;
    }   
}