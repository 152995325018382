@import 'src/styles/commons';

.extra-btn {
    .ant-btn {
        padding: 0px 16px;
        height: 32px;
        line-height: 24px;
        font-weight: 500;
    }
}
