.explanation_multiRate_information {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 400;
  margin-top: 8px;
}

.explanation_multiRate_information .anticon {
  margin-right: 8px;
}

.divider-text {
  color: #8c8c8c;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
}

.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  overflow: initial;
  padding: 24px 16px;

  h2 {
    margin-bottom: 50px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .section-title {
    display: flex;
    align-items: left;

    .section-icon {
      height: 100%;
      padding: 5px;
      background: #f5f5f5;
      border-radius: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      display: inline-flex;
      margin-right: 10px;
      color: #8c8c8c;
    }
  }

  .section-input {
    display: flex;
    align-items: left;
  }

  .ant-form-item {
    margin: 16px 0 8px;

    .ant-form-item-row {
      .ant-form-item-label {
        font-size: 12px;
        margin-right: 0;
        width: fit-content;
        max-width: 100%;
      }

      label {
        font-size: 12px;
      }

      label:after {
        content: none;
      }

      .ant-form-item-control {
        margin: 0 8px;
      }
    }
  }

  .form-errors {
    font-size: 12px;
    color: #ff2e63;
    margin-bottom: 12px;
  }

  .explanation_discount {
    font-size: 12px;
    color: #8c8c8c;
    font-weight: 400;

    &__percent {
      color: #ff2e63;
      font-weight: 600;
    }

    &__percent.disabled {
      color: #8c8c8c;
      font-weight: 600;
    }
  }
}

.section.disabled {
  background: #fafafa;
  color: #8c8c8c !important;
}

.divider {
  color: #f0f0f0;
}

.no-margin-divider {
  color: #f0f0f0;
  margin: 0;
}
