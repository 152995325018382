.tag {
  font-size: 10px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  background: #ffffff1f;
  padding: 1px 8px 1px 8px;
  border-radius: 62px;
  margin-right: 0;
}
