.inputContainer {
  margin-top: 16px;
  position: relative;
}

.inputLeftElement {
  position: absolute;
  top: -2px;
  left: 6px;
  font-weight: 500;
  font-size: 20px;
}

.input {
  padding-left: 20px;
  width: 90px;
}

.button {
  background-color: transparent;
  width: 50px;
  height: 20px;
  display: inline-flex;
  border: 2px solid rgb(255, 255, 255);
  box-shadow: rgb(204, 204, 204) 0px 0px 0px 1px;
}
