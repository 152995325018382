.custom-table {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  .title {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .table-rows {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #f2f2f2;

    .table-row {
      display: flex;
      padding: 16px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;

      &:not(:last-child) {
        border-bottom: 1px solid #f2f2f2;
      }

      .left-info {
        display: flex;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;

        .visual {
          display: flex;
          width: 56px;
          height: 56px;
          justify-content: center;
          align-items: center;
          position: relative;
          border-radius: 4px;
          object-fit: cover;
          background-color: #f9f9f9;
          overflow: hidden;

          .emoji {
            width: 32px;
            height: 32px;
          }

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .titles {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 2px;
          flex: 1 0 0;

          .title {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            align-self: stretch;

            overflow: hidden;
            color: #1a1a1a;
            text-overflow: ellipsis;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }

          .description {
            display: flex;
            align-items: center;
            gap: 4px;
            align-self: stretch;

            span {
              overflow: hidden;
              color: #808080;
              text-overflow: ellipsis;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }
          }
        }
      }

      .right-info {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 80px;
        color: #808080;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

        .primary-info {
          display: flex;
          width: 220px;
          align-items: center;
          justify-content: flex-start;
          gap: 10px;

          .info {
            display: inline-flex;
            padding: 4px 8px;
            align-items: center;
            gap: 8px;
            border-radius: 4px;

            &.isClickable {
              cursor: pointer;
              background: #f2f2f2;

              svg {
                width: 12px;
                height: 12px;

                path {
                  fill: #808080;
                }
              }
            }
          }
        }

        .secondary-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100px;
        }
      }
    }
  }
}
