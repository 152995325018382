@import 'src/styles/commons';

.emoji-modal {
    margin-left: 200px;

    .emoji-modal__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;

        .emoji-modal__thumbnail {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 73px;
            height: 73px;
            margin: 4px;
            background: rgb(255, 255, 255);
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .ant-modal-body {
        padding: 24px 24px 0px 24px;
        max-height: 630px;
        overflow-y: scroll;
    }

    .ant-modal-footer {
        visibility: hidden;
    }

}
