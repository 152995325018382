.howDoesItWork {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  padding: 24px 16px;
  border-top: solid 1px #f0f0f0;

  .howDoesItWork-collapse.ant-collapse {
    margin: 0;
    .explanation-panel {
      margin-top: 0;
      border: 1px solid #f0f0f0;
      > .ant-collapse-header {
        padding: 16px !important;
        display: flex;
        align-items: center;
        justify-content: center;

        .explanation-header {
          display: flex;
          align-items: center;

          .anticon {
            font-size: 24px;
            margin-right: 16px;
          }
          h3 {
            font-size: 14px;
            color: #262626;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
        }
      }

      .description-list {
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0;
        gap: 4px;

        &__item {
          color: #262626;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &--bold {
            font-weight: 600;
          }
        }
      }
    }
  }
}
