@import 'src/styles/commons';

.guide-layout {
    margin-left: 200px;
    background-color: #FFF;

    .ant-form {
        height: 100%;
    }

    .page-content {
        padding: 24px;
        flex: 1;
        overflow-y: scroll;

        .field-container {
            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
}
