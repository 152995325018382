@import 'src/styles/commons';

.panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  h3 {
    margin: 0;
    color: $grey700;
  }
}

.panel-header-titles {
  display: flex;
  flex-direction: column;
}

.ant-collapse-item-disabled {
  .panel-header {
    h3 {
      color: $grey500;
    }
  }
}
