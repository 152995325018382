.availability_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;

  .ant-divider-horizontal {
    margin: inherit;
  }

  .available_days,
  .available_on,
  .closing_period .title_button {
    display: flex;
    align-items: center;
    align-self: stretch;

    .title {
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
    }
  }
  .available_days {
    gap: 48px;

    &__radios {
      display: flex;
      gap: 24px;

      .ant-radio-wrapper {
        margin: inherit;
      }
    }
  }

  .select_days_available {
    display: flex;
    padding-left: 146px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;

    .weekday_switch {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;

      .day_disabled {
        color: #bfbfbf;
      }
    }
  }

  .available_on {
    gap: 63px;
    .checkedAndDisabled {
      * {
        color: inherit;
      }

      .ant-checkbox-inner {
        background-color: #ff3366;
        border-color: #ff3366 !important;

        &::after {
          border-color: #f5f5f5;
        }
      }
    }
  }
}
