@import 'src/styles/commons';

.space-modal {
    margin-left: 200px;

    .modal-content {
        .category-container {
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            .category-title {
                color: rgb(16, 16, 16);
                font-size: 16px;
                font-weight: 600;
                text-transform: capitalize;
            }

            .type-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 48px;
                border: 1px solid rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                padding: 16px;
                margin-top: 8px;

                .type-title {
                    color: rgb(16, 16, 16);
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: capitalize;
                }

                .counter-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 64px;
                    height: 16px;

                    .counter-minus {
                        cursor: pointer;

                        svg {
                            color: rgb(255, 46, 99);
                        }

                        &-disabled {
                            cursor: not-allowed;

                            svg {
                                color: rgb(191, 191, 191);
                            }
                        }
                    }

                    .counter-plus {
                        cursor: pointer;

                        svg {
                            color: rgb(255, 46, 99);
                        }

                        &-disabled {
                            cursor: not-allowed;

                            svg {
                                color: rgb(191, 191, 191);
                            }
                        }
                    }

                    .counter {
                        width: 16px;
                        line-height: 16px;
                        color: rgb(16, 16, 16);
                        font-size: 14px;
                        font-weight: 500;
                        text-align: center;
                    }
                }
            }
        }
    }

    .ant-modal-body {
        max-height: 600px;
        overflow-y: auto;
    }

    .ant-modal-title {
        color: rgb(16, 16, 16);
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
    }
}
