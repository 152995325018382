@import 'src/styles/commons';

.title_button {
  gap: 46px;

  .add-range:disabled:hover {
    color: rgba(0, 0, 0, 0.25);
  }
}

.list_closing_periods {
  padding-left: 150px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .range-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }

  .delete-range {
    color: #bfbfbf;
    &:hover {
      cursor: pointer;
    }
  }
}
