@import 'src/styles/commons';

.upload-zone {
    padding-right: 16px;
    padding-bottom: 24px;

    .ant-upload.ant-upload-drag {
        background-color: #FFF;
    }

    .ant-upload-drag-icon {
        .anticon.anticon-camera.icon {
            color: #000;
        }
    }

    .ant-upload {
        .ant-upload-text {
            color: rgb(16, 16, 16);
            font-size: 16px;
            line-height: 24px;
        }

        .ant-upload-hint {
            color: rgb(140, 140, 140);
            font-size: 14px;
            line-height: 20px;

            &.upload-zone-error-text {
                color: #F5222D;
            }
        }
    }
}
