$primary-color: #F36;
$primary-hover: #E62958;
$primary-dark: #7C0B2B;
$primary-medium: #911034;
$primary-light: #FFD5E0;
$primary-super-light: #FFE0E8;
$primary-extra-light: #FFF8EF;
$primary-border: #FFCEDA;

$success: #52C41A;
$warning: #FAAD14;
$danger: #F52F39;

$grey100: #F6F6F6;
$grey200: #EEE;
$grey300: #E0E0E0;
$grey400: #BBB;
$grey500: #7C7C7C;
$grey600: #555;
$grey700: #2D2D2D;
$grey800: #101010;


// Mixins
@mixin small-screen {
    @media (max-width: 1450px) {
        @content;
    }
}

@mixin big-screen {
    @media (min-width: 1450px) {
        @content;
    }
}

