.room {
  display: flex;
  //   height: 69px;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #f2f2f2;

  .room_image {
    width: 53px;
    height: 53px;
    align-self: stretch;
    border-radius: 4px;
  }

  .room_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    line-height: normal;

    .title {
      color: #000;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      text-align: left;
    }

    .description {
      overflow: hidden;
      color: #1a1a1a;
      text-overflow: ellipsis;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
    }

    .bed {
      overflow: hidden;
      color: #808080;
      text-overflow: ellipsis;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      text-align: left;
    }
  }
}
