@import 'src/styles/commons';

.booking {
  margin: 24px 24px 0;
  .ant-btn {
    margin-left: 5px;
    width: 32px;
  }

  .ant-table-thead > tr > th {
    background-color: #f0f0f0;
    padding-top: 0;
    padding-bottom: 0;
    height: 46px;
    border-top: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
  }

  th.sort-column {
    cursor: pointer;

    &:hover {
      background-color: #0000000a;
    }
  }

  .booking__footer {
    display: flex;
    justify-content: flex-end;
    padding: 16px;
    background-color: #fff;
  }
}

.inventory__row--disabled {
  color: $grey400;
}

.inventory__cell-warn {
  color: $danger;
  font-weight: 600;
}

.inventory__room-name {
  margin-left: -35px;

  .ant-badge {
    margin-right: 25px;
  }
}

.booking__icon {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.ant-table-filter-column {
  margin: 0;
}
