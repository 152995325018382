@import 'src/styles/commons';

.upload-modal {
    margin-left: 200px;

    .ant-modal-content {
        border-radius: 8px;
    }

    .ant-modal-header {
        border-bottom: 0;
        padding: 16px 24px 0px 24px;
    }

    .ant-modal-title {
        line-height: 32px;
    }

    .ant-modal-body {
        padding: 0;
    }

    .upload-modal__menu {
        padding: 0px 24px;
    }

    .upload-modal__body {
        padding: 12px;
        height: 465px;
        overflow-y: scroll;

        &-empty {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: center;
        }

        .upload-zone {
            margin-left: 6px;
            margin-right: 6px;
            margin-top: 6px;
            padding: 0 !important;
            padding-bottom: 24px !important;
        }
    }
}
