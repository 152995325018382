.editoStatusTagContainer {
  padding: 2px 8px;
  border-radius: 2px;
  align-items: center;
  gap: 6px;
  display: flex;
  background: #f4fbf6;
  float: left;
}

.editoStatusTagContainer > .title {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}

.editoStatusTagContainer > .status {
  width: 6px;
  height: 6px;
  border-radius: 6px;
}
