@import 'src/styles/commons';

.line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 16px;
    margin-right: 24px;
    margin-left: 24px;

    .left-title {
        font-weight: 500;
    }

    .right-value {
        margin-left: 20px;
        text-align: end;
    }
}
