@import 'src/styles/commons';

.welcome {
    width: 100%;
    height: 100%;

    .container {
        background-color: #FFF;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .block {
            width: 800px;
            height: 228px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 8px;

            .first-section {
                display: flex;
                flex-direction: row;
                height: 56px;
                padding: 12px 24px;

                .picture {
                    width: 24px;
                    height: 24px;
                    margin-top: 4px;
                    margin-bottom: 4px;
                }

                .title {
                    margin-left: 8px;
                    color: #101010;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 32px;
                }
            }

            .second-section {
                height: 116px;
                border-top: 1px solid rgba(0, 0, 0, 0.15);
                border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                padding: 24px;

                .title {
                    color: #101010;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                }

                .description {
                    margin-top: 8px;
                    color: #8C8C8C;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .third-section {
                display: flex;
                justify-content: flex-end;
                padding: 12px 24px;
                height: 56px;

                button {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }
    }
}
