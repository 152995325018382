.formSectionContainer {
    gap: 24px;
    display: flex;
    flex-direction: column;
}

.formSectionContainer > div > .title {
    color: black;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    word-wrap: break-word;
}


.formSectionContainer > div > .sub-title {
    color: #8C8C8C;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
