@import 'src/styles/commons';

.sortOption {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.quickFilterTable .ant-table {
  border: inherit;
  padding: 20px 24px;
}

.quickFilterTable .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background-color: white;
}

.quickFilterTable .ant-table-tbody > tr > td {
  background-color: white !important;
}

.quickFilterTable .ant-table .ant-table-title {
  padding: 0;
}
