@font-face {
    font-family: 'GTWalsheim';
    font-weight: 400;
    font-style: normal;
    src: url('GTWalsheim/GT-Walsheim-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'GTWalsheim';
    font-weight: 500;
    font-style: normal;
    src: url('GTWalsheim/GT-Walsheim-Medium.otf') format('opentype');
}

@font-face {
    font-family: 'GTWalsheim';
    font-weight: 600;
    font-style: normal;
    src: url('GTWalsheim/GT-Walsheim-Bold.otf') format('opentype');
}
