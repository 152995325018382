@import 'src/styles/commons';

.services-container {
  height: 100%;

  .staycation-card__content {
    padding-top: 28px;
    padding-bottom: 20px;
  }

  .ant-row.ant-form-item-row {
    .ant-form-item-label {
      margin-right: 16px;
      width: 192px;
      max-width: unset;

      label {
        font-weight: 500;
        font-size: 14px;
      }

      label::after {
        display: none;
      }
    }

    .ant-radio-disabled + span {
      color: rgb(16, 16, 16) !important;
    }

    .ant-select {
      width: 320px;
    }

    .FIELD {
      display: flex;

      .ant-select {
        width: 160px;
      }
    }
  }

  .price-kind {
    .ant-input-number {
      width: 104px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      overflow: hidden;
    }

    .ant-select {
      width: 156px;
      overflow: hidden;

      .ant-select-selector {
        border-left: none;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background: #fafafa;
      }
    }
  }

  .ant-form-item-subfield {
    margin-bottom: 8px !important;
  }

  .service-block {
    margin-bottom: 32px;
  }

  .opening-hours {
    .input-row {
      .ant-row.ant-form-item-row {
        .ant-form-item-label {
          label.ant-form-item-required::before {
            display: none;
          }

          label::after {
            display: none;
          }
        }
      }
    }
  }
}
