@import 'src/styles/commons';

.extranet-inventory-modal {
  .ant-modal-header {
    .ant-modal-title {
      height: 32px;
      line-height: 32px;
      color: #101010;
      font-size: 18px;
    }
  }

  .ant-modal-close {
    height: 64px;
  }

  .ant-modal-footer {
    padding-top: 16px;
    padding-bottom: 16px;

    button {
      font-weight: 500;
    }
  }

  &__danger {
    color: #f5222d;
    font-weight: 600;

    .anticon {
      margin-right: 4px;

      svg {
        height: 14px;
      }
    }
  }

  &__automatedDiscount {
    border: 1px solid #f0f0f0;
    background: #fafafa;
    padding: 16px;
    .anticon {
      color: #999999;
      width: 16px;
      height: 16px;
    }
    .ant-alert-content {
      color: #101010;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }

  ul {
    margin-left: 30px;
    color: #8c8c8c;
    font-size: 14px;
    font-weight: 300;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
  }
}
