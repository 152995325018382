@import 'src/styles/commons';

.room-modal {
    margin-left: 200px;

    .room-modal__item {
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px 16px;
        margin-bottom: 8px;

        &:hover {
            cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .room-modal__item__avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 54px;
            height: 40px;
            background: rgb(255, 255, 255);
            border: 1px solid rgba(0, 0, 0, 0.15);
            border-radius: 4px;

            img {
                border-radius: 4px;
                width: 100%;
                height: 80%;
                object-fit: contain;
            }
        }

        .ant-list-item-meta-content {
            align-self: center;

            .ant-list-item-meta-title{
                color: rgb(16, 16, 16);
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 0px;
                line-height: 20px;
                margin-bottom: 0;
            }

            .ant-list-item-meta-description {
                color: rgb(140, 140, 140);
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
            }
        }
    }

    .ant-modal-body {
        max-height: 600px;
        overflow-y: auto;
    }
}
