@import 'src/styles/commons';

.identity {
  .container {
    width: 660px;
  }

  .input-row {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0px;
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .ant-select {
        width: 320px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input {
        width: 440px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input-number {
        width: 104px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-form-item-label {
        margin-right: 16px;
        width: 192px;
        max-width: unset;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label.ant-form-item-required::before {
          display: none;
        }

        label::after {
          display: none;
        }
      }

      .view-text {
        line-height: 32px;
      }

      .view-text-area {
        white-space: pre-wrap;
      }
    }
  }
}
