@import 'src/styles/commons';

.checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    z-index: 1;

    svg {
        position: absolute;
        width: 24px;
        height: 24px;
    }
}

.checkmark__check {
    fill: $primary-color;
}
