@import 'src/styles/commons';

.left-right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-right-header__left {
  display: flex;
  flex-grow: 1;
  align-items: center;

  .ant-picker-range {
    width: 350px;
  }
}

.left-right-header__right {
  display: flex;
  align-items: flex-end;

  button {
    margin-left: 8px;
  }
}

.left-right-header__search {
  flex-basis: 270px;
  margin-right: 16px;

  .anticon {
    color: #bbb;
  }
}

.left-right-header__refresh {
  display: flex;
  align-items: center;
  text-align: right;

  .anticon {
    color: $primary-color;
    font-size: 24px;
    font-weight: 600;
  }
}

.left-right-header__last-refresh {
  margin-right: 16px;
  color: $grey500;
  font-size: 12px;
}
