@import 'src/styles/commons';

.section {
  margin-bottom: 40px;

  .section__title {
    color: $grey400;
  }

  .section__content {
    .ant-row.ant-form-item-row {
      margin-bottom: 32px !important;
    }
  }

  &--bordered {
    border: 1px solid $grey300;
    border-radius: 4px;
    padding: 20px 20px 0;
  }

  &--separated {
    border-bottom: 1px solid $grey300;
    padding: 10px 0;
  }
}
