.extraGuestModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 660px;
  .ant-modal-header {
    padding: 0;
    border-bottom: 0;
  }
  &__header {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-bottom: 1px solid #f0f0f0;
    h1 {
      color: #262626;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
    p {
      color: #808080;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .ant-modal-body {
    padding: 16px;

    max-height: 600px;
    overflow-y: auto;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;

    button.ant-btn.saveBtn {
      margin-left: 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h1 {
      color: #1a1a1a;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }

    .bodySection {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    .overview,
    .configuration {
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      display: flex;
      align-items: flex-start;
      align-self: stretch;
    }

    .overview {
      padding: 16px 0px;

      &__yourPackage,
      &__yourRooms {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;

        &__title {
          color: #808080;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          gap: 4px;
          line-height: normal;
        }
      }

      &__yourPackage {
        &__infos {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .infoPrice {
          display: flex;
          align-items: baseline;
          gap: 8px;

          .price {
            color: #1a1a1a;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
          }
          .priceDescription {
            color: #1a1a1a;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;

            .boldDescription {
              font-weight: 600;
            }
          }
        }
        .infoContent {
          display: flex;
          align-items: center;
          align-content: center;
          gap: 8px;
          align-self: stretch;
          flex-wrap: wrap;

          color: #808080;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;

          .ant-tag {
            color: #808080;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            border-radius: 4px;
            background: #f9f9f9;
            border: none;
            margin-right: 0;
          }
        }
      }

      &__divider {
        height: auto;
      }

      &__yourRooms {
        text-align: center;
        font-size: 12px;

        &__header {
          display: flex;
          justify-content: space-between;

          .pagination {
            display: flex;
            flex-direction: row;
            gap: 8px;

            button {
              display: flex;
              padding: 4px 7px;
              flex-direction: column;
              align-items: center;
              gap: 8px;

              .anticon svg {
                width: 16px;
                height: 16px;
              }
            }
          }
        }

        &__infos {
          display: flex;
          align-items: center;
          flex-direction: column;
          gap: 8px;
          .noRooms {
            color: #808080;
            width: 190px;

            &_icon {
              font-size: 24px;
            }

            &_title {
              font-weight: 500;
            }

            &_description {
              font-weight: 400;
            }
          }
        }
      }
    }

    .configuration {
      flex-direction: column;
      &.disabled {
        border: 1px solid #f0f0f0;
        background: #fafafa;

        .configuration__body__price,
        .configuration__header__title {
          color: #8c8c8c;
        }
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      &__header {
        display: flex;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex-direction: row;
        width: 100%;

        .ant-switch .anticon {
          color: #fff;
        }

        &__title {
          display: flex;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          color: #1a1a1a;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22px;

          .anticon {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-size: 14px;
            color: #8c8c8c;
            padding: 5px;
            border-radius: 40px;
            background: #f5f5f5;
          }
        }
      }

      &__body {
        display: flex;
        padding: 0px 16px 24px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;

        &__price {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #1a1a1a;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }

        &__info {
          display: flex;
          align-items: center;
          gap: 4px;
          color: #8c8c8c;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
}

.extra-guest-confirmation-popin {
  width: 350px;

  p {
    line-height: 22px;
    font-size: 14px;
  }

  .popin-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .popin-cta {
    margin-top: 12px;
    text-align: right;

    .popin-yes {
      height: 24px;
    }
  }
}
