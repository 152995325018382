@import 'src/styles/commons';

.time-range-section {
  .ant-radio-wrapper {
    margin-bottom: 8px;
  }

  .day-section {
    margin-top: 6px;

    .range-row {
      margin-top: 8px;
      display: flex;

      .add-range {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        width: 32px;
        height: 32px;
        font-weight: 500;
        color: rgb(16, 16, 16);
        margin-left: 12px;

        &:hover {
          border-color: #ff2e63;
        }

        .anticon {
          width: 14px;
          height: 14px;
        }
      }

      .delete-range {
        cursor: pointer;
        margin-top: 8px;
        margin-left: 20px;

        .anticon {
          display: flex;
          align-items: center;
          justify-content: center;
          color: rgb(191, 191, 191);
        }
      }

      .day-text {
        height: 16px;
        color: rgb(16, 16, 16);
        font-size: 14px;
        font-weight: normal;
        margin-left: 12px;
        margin-top: 8px;
        line-height: 16px;
        text-transform: capitalize;
      }

      .ant-picker.ant-picker-disabled {
        .ant-picker-input {
          input {
            color: rgb(16, 16, 16);
          }
        }
      }

      .ant-picker {
        width: 260px !important;
      }
    }
  }

  .range-row {
    margin-top: 8px;
    display: flex;

    .add-range {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      width: 32px;
      height: 32px;
      font-weight: 500;
      color: rgb(16, 16, 16);
      margin-left: 12px;

      &:hover {
        border-color: #ff2e63;
      }

      .anticon {
        width: 14px;
        height: 14px;
      }
    }

    .delete-range {
      cursor: pointer;
      margin-top: 8px;
      margin-left: 20px;

      .anticon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgb(191, 191, 191);
      }
    }

    .day-text {
      height: 16px;
      color: rgb(16, 16, 16);
      font-size: 14px;
      font-weight: normal;
      margin-left: 12px;
      margin-top: 8px;
      line-height: 16px;
      text-transform: capitalize;
    }

    .ant-picker.ant-picker-disabled {
      .ant-picker-input {
        input {
          color: rgb(16, 16, 16);
        }
      }
    }

    .ant-picker {
      width: 260px !important;
    }
  }

  .ant-radio-disabled + span {
    color: rgb(16, 16, 16) !important;
  }
}
