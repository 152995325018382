@import 'src/styles/commons';

.cover-form {
  height: 100%;

  .scard-with-pictures {
    &.deprecated {
      opacity: 0.5;
    }

    .staycation-card__header {
      padding: 16px 24px;
    }

    .staycation-card__content {
      padding: 24px !important;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .ant-form-item {
        margin-bottom: inherit;

        label {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }
}
