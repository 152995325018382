.staycation-card {
  margin-bottom: 24px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: initial;

  &-last {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .staycation-card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    padding: 16px;
    border-bottom: 1px solid #e0e0e0;

    &-no-border {
      border: 0;
    }

    .ant-switch {
      margin-right: 12px;
    }

    .staycation-card__header-left {
      display: flex;
      align-items: center;

      .staycation-card__header-text {
        .staycation-card__title {
          font-size: 18px;
          font-weight: 600;
          line-height: 32px;
        }

        .staycation-card__subtitle {
          color: rgb(140, 140, 140);
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
        }
      }
    }

    .staycation-card__header-right {
      justify-self: flex-end;
    }
  }

  .staycation-card__content {
    padding: 32px 16px;

    .ant-row.ant-form-item-row {
      margin-bottom: 0;
    }
  }

  .staycation-card__content_no-padding {
    padding: 0;
  }
}
