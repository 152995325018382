.editoSectionsList .collapses {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.editoSectionsList .ant-collapse {
    margin: inherit;
    
    .ant-collapse-item { 
        margin: inherit;
        border: inherit;
    }

    .ant-collapse-content > .ant-collapse-content-box {
        padding: 0px 28px;
    }

    .ant-collapse-header {
        padding: 0px !important;
        align-items: flex-end;

        .title {
            font-size: 20px;
            font-weight: 600; 
            line-height: 28px;
        }

        .ant-collapse-arrow {
            font-size: 16px;
        }
    }

    .deleteIcon {
        color: #BFBFBF;
        font-size: 15px;
    }
}

.editoSectionsList .ant-btn-background-ghost.ant-btn-primary:hover {
    background: transparent;
}

.addSectionButton > .icon {
    font-size: 16px;
}