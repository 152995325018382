@import 'src/styles/commons';

.feature-category {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    .label {
        color: #101010;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        text-align: right;
        width: 192px;
        margin-top: 7px;
        margin-right: 18px;
    }

    .buttons {
        width: 525px;

        button {
            border-radius: 16px;
            margin-right: 8px;
            margin-bottom: 8px;

            &:focus {
                border-color: rgb(217, 217, 217);
            }

            &.check-button--checked {
                border-color: #FF2E63;

                span {
                    color: #FF2E63;
                }
            }

            span {
                color: #101010;
                font-weight: normal;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}
