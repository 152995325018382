@import 'src/styles/commons';

.upload-item {
    display: flex;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin: 6px;
    width: 114px;
    height: 114px;

    &-sortable {
        cursor: all-scroll;
    }

    .upload-item__thumbnail {
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 4px;
        margin: 8px;
        width: 96px;
        height: 96px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .upload-item__hover-area {
        opacity: 0;
        position: absolute;
        border-radius: 4px;
        margin: 8px;
        width: 96px;
        height: 96px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            opacity: 1;
            background: rgba(0, 0, 0, 0.48);
        }

        .upload-item__hover-buttons {
            .anticon {
                cursor: pointer;
                width: 16px;
                height: 16px;
                margin: 0 4px;
                color: #FFF;
            }
        }
    }
}
