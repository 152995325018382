@import 'src/styles/commons';

.basic-info {
  .input-row.area {
    .ant-input-number-input-wrap {
      &::after {
        top: 8px;
        right: 12px;
        line-height: 16px;
        position: absolute;
        color: #bfbfbf;
        font-size: 14px;
        content: 'm²';
      }
    }
  }

  .fixed-width-number-input {
    width: 104px;
  }

  .no-margin-bottom {
    margin-bottom: 0px;
  }
}

.maxRoomContainer {
  display: flex;
  gap: 8px;
}
