@import 'src/styles/commons';

.slist-item {
    .ant-list-item-meta-content {
        align-self: center;

        .ant-list-item-meta-title{
            color: rgb(16, 16, 16);
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0px;
            line-height: 20px;
            margin-bottom: 0;
        }

        .ant-list-item-meta-description {
            color: rgb(140, 140, 140);
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
        }
    }
}
