@import 'src/styles/commons';

.add-experiences {
  .error-message {
    margin: 16px 32px;
    color: red;
  }

  .experience-row {
    align-items: baseline;

    .delete-icon {
      position: relative;
      left: -189px;
      margin-left: 12px;
      font-size: 16px;
      color: #bfbfbf;
      margin-top: 8px;
    }
  }

  .ant-form-item-control-input-content {
    button {
      border-style: dashed;
      width: 320px;
    }
  }

  .ant-row .ant-col .ant-form-item-label {
    margin-right: 16px;
    margin-left: 24px;
  }

  .ant-form-item-control-input {
    width: 320px;
  }

  .flex {
    display: flex;
    flex-direction: row;
  }

  .with-delete {
    flex-grow: 1;

    .ant-form-item-control-input {
      width: 346px;
    }
  }

  .ant-form-horizontal .ant-form-item-control {
    width: 320px;
  }

  .container-experience-item {
    display: flex;
  }
  .ant-cascader-menu-item-content {
    white-space: normal;
    height: auto;
    min-width: 140px;
    overflow: hidden;
  }
}
