.tooltip_container {
  gap: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  width: 247px;
}
.tooltip_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.tooltip_title {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

.tooltip_main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  width: 100%;
  gap: 12px;
}

.tooltip_main > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  gap: 8px;
}

.mapping_color {
  width: 12px;
  border-width: 2px;
  border-style: solid;
}

.tooltip_main .text_date {
  width: 100%;
}

.tooltip_main .text,
.tooltip_main .data {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  color: #000000;
}

.tooltip_main .text {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
}

.tooltip_main .date {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0px;
  color: #b3b3b3;
}

.tooltip_main .date.addMargin {
  margin-left: 20px;
}

.tooltip_main .data {
  align-self: baseline;
  text-wrap: nowrap;
}
