@import 'src/styles/commons';

.spaces-form {
    .form-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .staycation-card {
        width: 100%;
        max-width: 802px;
    }

    .card-caret {
        margin-right: 12px;

        &:hover {
            cursor: pointer;
        }

        span {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .card-delete {
        &:hover {
            cursor: pointer;
        }

        .anticon {
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgb(191, 191, 191);
        }
    }

    .spaces__add-button-container{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        .spaces__add-button{
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: 1px dashed rgba(0, 0, 0, 0.15);
            width: 140px;
            height: 32px;
            font-weight: 500;
            color: rgb(16, 16, 16);

            &:hover {
                border-color: #FF2E63;
            }

            .anticon svg {
                width: 14px;
                height: 14px;
                margin-right: 4px;
            }
        }
    }

    .ant-empty {
        margin: 0 !important;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .scard-with-pictures {
        .staycation-card__content {
            padding: 18px !important;
        }
    }
}
