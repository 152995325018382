.notifEmailContainer {
  .ant-row {
    &.ant-form-item-row {
      .ant-form-item-label {
        // Override what is written in form.scss
        width: 100%;
        max-width: none;
      }
    }
  }
}
