.editoRow {
    &.ant-row{
        flex-wrap: wrap;
    }
}

.locationSelects .first-select > .ant-select-selector {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
.locationSelects .second-select > .ant-select-selector {
    border-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }