@import 'src/styles/commons';

.edit-modal {
  margin-left: calc(200px + (100% - 1000px) / 2);
}
.edit-picture-modal {
  .thumbnail {
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 4px;
    width: 265px;
    height: 245px;
    overflow: hidden;

    img {
      width: 265px;
    }
  }

  .infos {
    margin-top: 10px;
    color: $grey500;
  }

  .ant-row.ant-form-item-row {
    margin-bottom: 16px;
    flex-direction: column;

    .ant-col.ant-form-item-label {
      text-align: left;

      label {
        line-height: 16px;
        color: #101010;
        font-size: 14px;
        font-weight: 500;

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
