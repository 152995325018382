.subMenuIcon {
  display: flex;
  margin-right: 10px;
}

.subMenuElement {
  display: flex;
  align-items: center;
}

.subTagNew {
  margin-left: auto;
  display: flex;
  align-items: center;
}
