.giftcard-edit-btn {
  .ant-btn {
    padding: 0 16px;
    height: 32px;
    line-height: 24px;
    font-weight: 500;
  }
}

td.ant-table-column-sort {
  background: none;
}

.ant-table-thead th.ant-table-column-sort {
  background: #f8f8f8;
}

.strong {
  font-weight: 500;
}

.bloc {
  .ant-table {
    border: none;
  }
}
