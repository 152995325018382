.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.search {
  max-width: 250px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  min-width: 106px;

  [ant-click-animating-without-extra-node]::after {
    animation: none !important;
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -ms-animation: none !important;
  }

  button {
    margin-left: 5px;
    width: 32px;

    &:first-child {
      margin-left: 0;
    }

    &:hover,
    &:active,
    &:focus {
      border-color: #d9d9d9;
      color: #2d2d2d;
    }
  }
}
