.voucher-modal {
    .ant-form-item-label {
        margin-right: 16px;
        width: 192px;
        max-width: unset;
    
        label {
            font-weight: 500;
            font-size: 14px;
        }
    
        label.ant-form-item-required::before {
            display: none;
        }
    
        label::after {
            display: none;
        }
    }
}
