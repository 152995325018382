@import 'src/styles/commons';

.extranet-table {
  margin-left: 200px;
  background-color: #fff;
  width: 100%;

  .ant-layout {
    background-color: #fff;
  }

  .header {
    margin-bottom: 24px;
    border-bottom: 1px solid #e0e0e0;
    background: #fff;
    padding: 0 0 0 24px;
    color: #101010;
    font-size: 20px;
    font-weight: 600;
  }

  .body {
    position: relative;
    margin: 24px;

    .body-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;

        .ant-picker-range {
          width: 350px;
        }
      }

      .checkin-input-title {
        white-space: nowrap;
        margin-right: 8px;
        margin-left: 16px;
      }

      .right {
        display: flex;
        align-items: flex-end;
      }

      .search {
        flex-basis: 270px;
        margin-right: 16px;
        height: 32px;
      }

      .range-picker {
        height: 32px;
        color: #bbb;
      }

      .ant-input-suffix,
      .ant-picker-suffix {
        line-height: initial;
        color: #bbb;
      }

      .button {
        margin-right: 8px;
        margin-left: 16px;
        height: 32px;
      }

      .button-wrapper {
        margin-left: 16px;
      }
    }

    .ant-table-title,
    .ant-table-container {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #888;
      }

      &::-webkit-scrollbar:vertical {
        display: none;
      }
    }

    .ant-table-thead > tr > th {
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
      padding: 0 8px;
      height: 46px;

      .ant-table-column-sorters-with-tooltip {
        min-width: 111px;
      }
    }

    .ant-table-tbody > tr > td {
      padding: 8px;
    }

    th.sort-column {
      background-color: #f8f8f8;
      cursor: pointer;
      line-height: 38px;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    td.ant-table-column-sort {
      background: none;
    }
    .ant-table-thead th.ant-table-column-sort {
      background: #f8f8f8;
    }

    .sort-button {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-width: 0;
      border-radius: 4px;
      padding: 0;
      width: 100%;
      height: 100%;
      font-weight: 500;
      transition-duration: 0.4s;
    }

    .footer {
      padding: 16px 0;
    }

    .button-wrapper,
    .footer {
      display: flex;
      justify-content: flex-end;
      min-width: 106px;

      [ant-click-animating-without-extra-node]::after {
        animation: none !important;
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -ms-animation: none !important;
      }

      button {
        margin-left: 5px;
        width: 32px;

        &:first-child {
          margin-left: 0;
        }

        &:hover,
        &:active,
        &:focus {
          border-color: #d9d9d9;
          color: #2d2d2d;
        }
      }
    }
  }
}
