.extranet-package {
  position: relative;
  overflow: auto;

  &.noContent {
    height: 100%;
  }

  .unpublished-package-alert-container {
    padding: 32px 24px 0;

    .unpublished-package-alert.ant-alert {
      border: none;
      background: #f9f9f9;
      width: 100%;

      .anticon {
        color: #808080;
      }
    }
  }

  .extranet-package-container {
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 48px;
  }
}
