@import 'src/styles/commons';

.guide-layout {
  margin-left: 200px;
  background-color: #fff;

  .ant-form {
    height: 100%;
  }

  .page-content {
    padding: 24px;
    flex: 1;
    overflow-y: scroll;

    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
