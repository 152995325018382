.editoSectionsForm {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 24px;
}

.imageRow {
    display: flex;
    flex-direction: row;
    align-items: center;

    .ant-form-item {
      margin-bottom: inherit;
    }

    .upload-list .upload-list__new-item div {
      margin: inherit
    }
}