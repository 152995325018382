@import 'src/styles/commons';

.hotel-marks {
    .star {
        margin-left: 12px;
        color: #FAAD14;
        font-size: 16px;
    }

    .average {
        margin-left: 4px;
        line-height: 19px;
        color: #FAAD14;
        font-size: 16px;
        font-weight: 500;
    }

    .counter {
        margin-left: 8px;
        line-height: 19px;
        color: #8C8C8C;
        font-size: 16px;
        font-weight: normal;
    }
}
