.chartsContainer {
  width: calc(50% - 8px);
  border-radius: 8px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  padding: 24px 24px 32px 24px;
}

.chartsTitle {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #1a1a1a;
  display: flex;
  gap: 8px;
  align-items: center;
}

.chartsTitle .anticon {
  font-size: 12px;
  color: #b3b3b3;
}

.emptyChartsContainer {
  margin: 148px 116px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.emptyChartsIcon {
  width: 40px;
  height: 40px;
  color: #bfbfbf;
  font-size: 40px;
}

.emptyChartsText {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.emptyChartsTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

.emptyChartsDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #000000;
}
