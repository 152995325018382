.editoListActionsContainer {
    display: flex;
    flex-direction: column;
}

.editoListActionLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #262626;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 9px 16px;
    

    &.danger {
        color: #FF382C
    }

    .icon {
        font-size: 16px;
    }
}