.page-list {
  .welcome-kit-container {
    display: flex;
    flex-direction: column;
    max-width: initial;

    &.body .ant-table-thead > tr > th {
      border-top: 0;
      border-bottom: 0;
    }

    .stock-status .sort-button {
      justify-content: space-between;
    }
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
}
