.row-dragging {
    background: #fafafa;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
  
    border-bottom: 1px solid #fafafa;
  }
  
  .row-dragging td {
    padding: 0 10px;
  }
  
  .row-dragging .drag-visible {
    visibility: visible;
  }