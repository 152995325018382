.editoSectionActionItemContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
}

.editoSectionActionItemContainer > .title {
    color: #262626; 
    font-size: 14px; 
    font-weight: 400; 
    line-height: 22px;
}