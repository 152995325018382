@import 'src/styles/commons';

.new-hotel-form {
    display: flex;
    align-items: center;
    background: #FFF;

    .page__form-header {
        align-items: center;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 1;

        .title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 0;
        }
    }

    .body {
        padding-bottom: 24px;
        min-height: unset;

        .steps {
            margin-top: 24px;
            margin-bottom: 24px;

            .ant-steps-item-icon {
                max-height: 24px;
                max-width: 24px;
                line-height: 24px;
                font-size: 14px;
            }

            .ant-steps-item-title::after {
                top: 14px;
            }
        }

        .ant-form {
            margin-top: 24px;
            margin-bottom: 24px;
            background-color: #FFF;
            width: 800px;

            .title {
                display: flex;
                align-items: center;
                border-bottom: #E0E0E0 solid 1px;
                padding-left: 24px;
                height: 65px;
                line-height: 32px;
                letter-spacing: 0;
                font-size: 18px;
                font-weight: 600;
            }

            .staycation-card.first-step,
            .staycation-card.second-step,
            .staycation-card.third-step,
            .staycation-card.fourth-step {
                .staycation-card__content {
                    padding: 24px 0 0 0;

                    .ant-collapse {
                        margin: 0;
                    }

                    .ant-form-item {
                        margin-bottom: 24px;
                    }

                    .ant-form-item-label {
                        margin-right: 16px;
                        width: 35%;
                        max-width: 192px;
                        font-weight: 500;
                    }

                    .ant-form-item-label > label::before,
                    .ant-form-item-label > label::after {
                        display: none !important;
                    }
                }
            }
        }
    }
}
