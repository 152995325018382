.input-datepicker {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: relative;

    label {
        font-weight: 500;
        font-size: 14px;
        width: 192px;
        margin-right: 28px;
        text-align: right;
        padding-top: 5px;
    }

    .ant-picker-range {
        width: 240px;
    }

    .anticon-arrow-right {
        margin-left: 12px;
        margin-right: 12px;
    }
}
