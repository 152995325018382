@import 'src/styles/commons';

.discover-form {
    height: 100%;

    .scard-with-pictures {
        .staycation-card__content {
            padding: 18px !important;
        }
    }
}
