@import 'src/styles/commons';

.gift-cards-list {
  margin-left: 200px;
  background-color: #fff;
  width: 100%;

  .ant-layout {
    background-color: #fff;
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 20;
    border-bottom: 1px solid #e0e0e0;
    background-color: #fff;
    width: 100%;

    .ant-page-header-heading-left {
      margin: 0;
    }
  }

  .body {
    position: relative;
    margin: 24px 24px 0;

    .body-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        display: flex;
        align-items: center;
      }

      .right {
        display: flex;
        align-items: flex-end;
      }

      .search {
        width: 280px;
        margin-right: 16px;
        height: 32px;
      }

      .ant-input-suffix {
        line-height: initial;
        color: #bbb;
      }

      .button-wrapper {
        margin-left: 16px;
      }
    }

    .ant-table-title,
    .ant-table-container {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #888;
      }

      &::-webkit-scrollbar:vertical {
        display: none;
      }
    }

    .ant-table-thead > tr > th {
      border-top: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
    }

    .ant-table-tbody {
      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }

    th.sort-column {
      cursor: pointer;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    th.action-column {
      width: 50px;
    }

    .footer {
      padding: 16px 0;
    }

    .button-wrapper,
    .footer {
      display: flex;
      justify-content: flex-end;
      min-width: 106px;

      [ant-click-animating-without-extra-node]::after {
        animation: none !important;
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -ms-animation: none !important;
      }

      button {
        margin-left: 5px;
        width: 32px;

        &:first-child {
          margin-left: 0;
        }

        &:hover,
        &:active,
        &:focus {
          border-color: #d9d9d9;
          color: #2d2d2d;
        }
      }
    }
  }
}
