@import 'src/styles/commons';
h1,
h2,
h3,
p,
div {
  margin: 0;
}
.bulkEdit {
  h2 {
    color: #262626;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  h3 {
    color: #262626;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;

    span {
      margin-right: 8px;
    }

    .anticon {
      color: #bbb;
    }
  }

  .subtitle {
    color: #8c8c8c;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    margin-top: 4px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h1 {
      color: #262626;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 0;
      margin-right: 6px;
    }
    h2 {
      color: #101010;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-bottom: 0;
    }
  }

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__body {
    flex: 1 0 auto;
    padding: 32px 24px 24px 24px;

    div[class^='ant-'] {
      margin: 0;
    }

    .ant-row.ant-form-item-row {
      display: flex;
      flex-direction: column;

      .ant-form-item-label {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        text-align: left;
      }
    }

    &__selectedDates {
      margin-bottom: 32px;

      &_pickers {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 16px;
        margin-bottom: 16px;

        > div {
          width: 100%;
        }
        .from {
          margin-right: 24px;
        }
        .name {
          color: #000;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
        }
      }
      &_applyTo {
        .listCheckbox {
          margin-top: 4px;
          .ant-checkbox + span {
            padding: 0;
            margin-left: 4px;
            margin-right: 16px;
            color: #262626;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
          }
        }
      }
    }

    &__values {
      .values_wrappers {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px;
        justify-content: center;
        border-radius: 2px;
        border: 1px solid #f4f3f3;
        margin-top: 16px;

        .title_switch {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .title_tooltip {
            display: flex;
            align-items: center;

            .anticon-info-circle {
              color: #999999;
            }
            :last-child {
              margin-left: 8px;
            }
          }
        }
        .subtitle {
          max-width: 334px;
        }
        .ant-input-affix-wrapper {
          display: flex;
          align-items: center;
          align-self: stretch;
        }
        .ant-input-number-input {
          display: flex;
          align-items: center;
          gap: 10px;
          flex: 1 0 0;
        }

        .ant-form-item-explain-error {
          font-size: 12px;
          color: #f5222d;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding-left: 12px;
          margin-top: 8px;
        }

        .inputContainer {
          width: 160px;
          margin-top: 16px;
          div.ant-input-number-in-form-item,
          div.ant-input-number {
            width: 100%;
          }
          .input {
            margin-bottom: 0;
          }
        }
      }
    }

    &__selected-room {
      margin-bottom: 32px;
      .room-select {
        margin-top: 16px;
      }
    }

    .detail {
      margin-top: 8px;
      width: 100%;
      color: #afafaf;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-left: 12px;

      &.crossedText {
        text-decoration: line-through;
      }
      &.red-text {
        color: #f5222d;
      }
      &.green-text {
        color: #52c41a;
      }
      &.grey-text {
        color: #bfbfbf;
      }
    }
  }
  &__footer {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80px;
    border-top: 1px solid #f0f0f0;
    flex-shrink: 0;
    position: sticky;
    bottom: 0;
    background: #fff;

    button:first-child {
      margin-right: 12px;
    }
  }
}
