.empty-state-container {
  padding: 48px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  align-self: center;
  justify-content: center;
  height: 100%;
}

.empty-state-container .anticon {
  width: 40px;
  height: 40px;
  color: #bfbfbf;
  font-size: 40px;
}

.empty-state-texts {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 310px;
}

.empty-state-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #000000;
}

.empty-state-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  color: #000000;
}
