.disclaimer-bloc {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding-top: 24px;
    padding-bottom: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #FFF;

    .disclaimer-left {
        .spacer {
            margin-bottom: 24px;
        }
    }

    .disclaimer-right {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #bfbfbf;

        span:first-child {
            margin-right: 16px;
            svg {
                cursor: pointer;
            }
        }
    }
}
