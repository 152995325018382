.editoLocationTagContainer {
  padding: 2px 8px;
  background: #f9f9f9;
  border-radius: 2px;
  align-items: center;
  float: left;
  gap: 6px;
  display: inline-flex;
}

.editoLocationTagContainer > .title {
  color: #808080;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
}
