@import 'src/styles/commons';


.ant-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    text-shadow: none;
    transition-duration: 0s;

    &:focus {
        outline: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    &.ant-btn-default {
        border: 1px solid $grey300;

        &:hover {
            background: $grey100;
            color: $grey700;
        }

        &:active {
            color: inherit;
        }

        &:focus {
            color: inherit;
        }
    }

    &.ant-btn-primary {
        &:hover {
            background: $primary-hover;
        }
    }

    &[disabled]:not(.ant-btn-link) {
        background: $grey100;

        &:hover {
            background: $grey100;
        }
    }

    .anticon {
        display: flex;
    }
}
