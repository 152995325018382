.page-list .ant-pagination-item,
.page-list .ant-pagination-jump-next-custom-icon,
.page-list .ant-pagination-jump-prev-custom-icon {
  display: none;
}

.page-list .ant-pagination-item.ant-pagination-item-active {
  display: inline-block;
  border: 1px solid #d9d9d9;
}

.page-list .ant-pagination-item.ant-pagination-item-active a {
  color: #2d2d2d;
}
