.ant-row {
  flex-wrap: nowrap;

  &.ant-form-item {
    margin-bottom: 32px;

    .ant-form-item-label {
      margin-right: 20px;
      flex-shrink: 0;
      width: 35%;
      max-width: 120px;
    }

    .ant-form-item-control {
      flex-shrink: 1;
    }
  }

  &.ant-form-item-row {
    .ant-form-item-label {
      margin-right: 20px;
      flex-shrink: 0;
      width: 35%;
      max-width: 120px;
    }

    .ant-form-item-control {
      flex-shrink: 1;
    }
  }

  .ant-col {
    &:first-child {
      .ant-form-item-label {
        width: 120px;
      }
    }
  }
}
