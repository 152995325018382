.user-details {
  margin-left: 200px;
  background-color: #fff;

  .title {
    display: flex;
    align-items: center;
    border-bottom: #e0e0e0 solid 1px;
    padding-left: 24px;
    height: 65px;
    line-height: 32px;
    letter-spacing: 0;
    font-size: 18px;
    font-weight: 600;
  }

  .bloc {
    margin-bottom: 24px;
    background-color: #fff;
    border: #e0e0e0 solid 1px;
    border-radius: 4px;
    .ant-table {
      border: none;
    }
  }

  .strong {
    font-weight: 500;
  }

  .actions-column {
    width: 40px;
  }

  .ant-table-thead > tr > th {
    width: 20%;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    :not(:last-child) {
      margin-right: 24px;
    }
  }

  .header {
    position: fixed;
    top: 0;
    z-index: 20;
    border: #e0e0e0 solid 1px;
    width: calc(100% - 200px);
  }

  .body {
    flex-direction: row;
    overflow: initial;

    .left-part {
      position: fixed;
      top: 74px;
      background-color: #f5f5f5;
      width: 436px;
      height: 100%;
      padding: 24px;
    }

    .right-part {
      margin-top: 74px;
      margin-left: 436px;
      padding: 24px;
    }

    .small {
      max-width: 600px;
      min-width: 500px;
      flex-grow: 1;
    }

    .header-title-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: #e0e0e0 solid 1px;
      padding-right: 24px;
      padding-left: 24px;
      height: 65px;

      .title-with-button {
        line-height: 32px;
        letter-spacing: 0;
        font-size: 18px;
        font-weight: 600;
      }

      .button {
        margin-left: 16px;
        font-weight: 500;
      }
    }

    .title-with-button2 {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: #e0e0e0 solid 1px;
      padding-right: 24px;

      .label {
        display: flex;
        align-items: center;
        padding-left: 24px;
        height: 65px;
        line-height: 32px;
        letter-spacing: 0;
        font-size: 18px;
        font-weight: 600;
      }

      .button {
        margin-left: 16px;
        font-weight: 500;
      }
    }

    .user-content {
      display: flex;
      flex-direction: row;
      padding-left: 24px;

      .avatar {
        margin-top: 16px;
        width: 78px;
        height: 78px;
      }

      .details {
        display: flex;
        flex-direction: column;
        width: 100%;

        .name {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
          margin-right: 24px;
          line-height: 24px;
          letter-spacing: 0;
          font-size: 14px;

          .link {
            text-decoration: underline;
            color: #000;
          }
        }

        .birth-date {
          display: flex;
          justify-content: flex-end;
          margin-top: 8px;
          margin-right: 24px;
        }

        .staycations {
          display: flex;
          justify-content: flex-end;
          margin-top: 8px;
          margin-right: 24px;
        }
      }
    }

    .referral-code {
      margin-bottom: 16px;
    }

    .generic-content {
      padding-bottom: 16px;

      .line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
        margin-right: 24px;
        margin-left: 24px;

        .left-title {
          font-weight: 500;
        }

        .link {
          text-decoration: underline;
          color: #000;
        }
      }
    }
  }

  .ant-popover {
    width: 360px;
  }

  td.ant-table-column-sort {
    background: none;
  }

  th.ant-table-column-sort {
    background: #f8f8f8;
  }

  .user-edit-btn {
    .ant-btn {
      padding: 0 16px;
      height: 32px;
      line-height: 24px;
      font-weight: 500;
    }
  }
}
