.editoEventDetailLayout {
    margin-left: 200px;
    flex: 1;
  }

.editoEventDetailLayout .container{
    background: #F6F6F6;
    gap: 24px;
    display: flex;
    flex-direction: column;
}