.booking-details {
  margin-left: 200px;
  background-color: #fff;

  .header {
    position: sticky;
    top: 0;
    z-index: 20;
    border: #e0e0e0 solid 1px;
    background-color: #fff;
    width: 100%;
  }

  .body {
    display: flex;
    flex-direction: row;
    overflow: initial;

    .left-part {
      position: fixed;
      height: 100%;
      top: 74px;
      background-color: #f5f5f5;
      width: 436px;
      padding-bottom: 24px;
    }

    .right-part {
      padding-bottom: 24px;
      margin-left: 436px;
      .bloc {
        width: 560px;
      }
    }

    .bloc {
      margin-top: 24px;
      margin-right: 24px;
      margin-left: 24px;
      border: #e0e0e0 solid 1px;
      border-radius: 4px;
      background-color: #fff;

      .header-title-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: #e0e0e0 solid 1px;
        padding-right: 24px;
        padding-left: 24px;
        height: 65px;

        .title-with-button {
          line-height: 32px;
          letter-spacing: 0;
          font-size: 18px;
          font-weight: 600;
        }

        .button {
          margin-left: 16px;
          font-weight: 500;
        }
      }

      .title {
        display: flex;
        align-items: center;
        border-bottom: #e0e0e0 solid 1px;
        padding-left: 24px;
        height: 65px;
        line-height: 32px;
        letter-spacing: 0;
        font-size: 18px;
        font-weight: 600;
      }

      .title-with-button2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: #e0e0e0 solid 1px;
        padding-right: 16px;

        .label {
          display: flex;
          align-items: center;
          padding-left: 24px;
          height: 65px;
          line-height: 32px;
          letter-spacing: 0;
          font-size: 18px;
          font-weight: 600;
        }

        .button {
          font-weight: 500;
        }
      }

      .user-content {
        display: flex;
        flex-direction: row;
        padding-left: 24px;

        .avatar {
          margin-top: 16px;
          margin-bottom: 16px;
          width: 104px;
          height: 104px;
        }

        .details {
          display: flex;
          flex-direction: column;
          width: 100%;

          .name {
            display: flex;
            justify-content: flex-end;
            margin-top: 24px;
            margin-right: 24px;
            line-height: 24px;
            letter-spacing: 0;
            font-size: 14px;

            .link {
              text-decoration: underline;
              color: #000;
            }
          }

          .birth-date {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            margin-right: 24px;
          }

          .staycations {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
            margin-right: 24px;
          }
        }
      }

      .generic-content {
        padding-bottom: 16px;

        .line {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 16px;
          margin-right: 24px;
          margin-left: 24px;

          .left-title {
            font-weight: 500;
          }

          .link {
            text-decoration: underline;
            color: #000;
          }
        }
      }
    }
  }

  .ant-popover {
    width: 360px;
  }
}
