@import 'src/styles/commons';

.subtitle {
    color: var(--color-gray-900, var(--text-primary, #1A1A1A));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.input-container {
    display: flex;
    width: 196px;
    flex-direction: column;
    align-items: flex-start;
    width: fit-content;
    gap: 8px;

    > label {
        font-weight: 400;
    }
}

.quantity-segment {
    margin-top: 24px;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: var(--color-gray-25, #F9F9F9);

    .quantity-span {
        color: var(--color-gray-900, var(--text-primary, #1A1A1A));
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }

    .added-quantity {
        color: var(--color-primary-500, #FF2E63);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
    }
}
