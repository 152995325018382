.dashboardHeader {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.customizeDashboard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  padding: 16px 20px 0px 20px;
}

.customizeDashboard .leftPart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.customizeDashboard .rightPart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.customizeDashboard .headerSelect {
  width: 186px;
}

.headerSelect.period .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.periodRange {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.rangeFooter {
  width: 100%;
  padding: 8px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dashboardNavigation .menu {
  display: flex;
  flex-direction: row;
  gap: 32px;
  padding: 0px;
  margin: 0px;
}

.dashboardNavigation .menu .menu-items {
  list-style: none;
}

.optionDashboardItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionDashboardItem .optionValue {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #1a1a1a;
}
.optionDashboardItem .optionValue.selected {
  color: #ff2e63;
}

.optionDashboardItem .previousDates {
  font-size: 12px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #808080;
}
