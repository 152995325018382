@import 'src/styles/commons';

.extranet-inventory__grid {
  .extranet-inventory {
    &__date-cell {
      background-color: #f5f5f5;
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      width: 56px;
      color: #262626;

      &--to-duplicate {
        background-color: #e5e5e5;
      }
    }
  }
}
