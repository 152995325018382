@import 'src/styles/commons';

.hotel-builder {
    .media-form-container {
        display: flex;
        justify-content: center;
    }

    .media-form {
        display: flex;
        flex-direction: column;
        width: 802px;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.15);

        .media-form-header {
            padding: 12px 24px 16px 24px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .title {
                color: rgb(16, 16, 16);
                font-size: 18px;
                font-weight: 600;
                line-height: 32px;
            }

            .explanation {
                color: rgb(140, 140, 140);
                font-size: 14px;
                font-weight: normal;
                line-height: 20px;
            }
        }

        .media-form-body {
            padding: 24px 8px 18px 24px;
        }
    }
}
