@import 'src/styles/commons';

.hotel-builder-sider {
    .standalone-item {
        cursor: default !important;

        a {
            cursor: default !important;
        }

        .ant-badge-status-dot {
            height: 8px;
            width: 8px;
        }
    }
    .menu {
        .standalone-item.not-done {
            opacity: 0.3;
        }
    }

    .done {
        .ant-badge-status-dot {
            background-color: #52C41A !important;
        }
    }

    .in-progress {
        .ant-badge-status-dot {
            background-color: #FA8C16 !important;
        }
    }

    .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .in-progress.ant-menu-item-selected {
        border-color: #FA8C16;
    }

    .not-done {
        .ant-badge-status-dot {
            background-color: #FFF !important;
        }
    }

    .ant-badge-status {
        margin-left: 4px;
        margin-right: 12px;
        background: none !important;
    }
}
