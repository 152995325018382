.validationBulkEditModalClassname.ant-modal-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    display: flex;
    width: 520px;
    flex-direction: column;
    align-items: flex-start;
    top: 0;
    margin: 0;
    .ant-modal-content {
      width: 100%;
    }
    &__validDays {
      color: #262626;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
    &__errorDays {
      color: #ff382c;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
    }
  }
}
