.services-form {
    display: flex;
    justify-content: center;

    .services-container {
        display: flex;
        flex-direction: column;
        width: 802px;
    }
}
