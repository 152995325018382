@import 'src/styles/commons';


.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 24px;
    border: 1px solid $grey300;
    border-radius: 4px;
    background: #FFF;
    padding: 50px;
    overflow: initial;

    h2 {
        margin-bottom: 50px;
    }
}

.card__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.card__body--form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .card__content {
        @include big-screen {
            padding-right: 50px;
        }

        width: 100%;
        max-width: 900px;
    }
}
