.preview-step {
  > div:not(:last-child) {
    padding: 0 16px;
  }

  .collapses {
    width: 100%;
    max-height: 350px;
    overflow-y: auto;

    .collapse-container {
      &.ant-collapse {
        margin: inherit;
        background-color: #fafafa;
        border: 0;
      }

      .ant-collapse-item {
        margin-top: inherit;
        border-radius: 0 !important;
        background-color: inherit;
        border-bottom: 0;

        &:first-child {
          border-top-left-radius: 4px !important;
          border-top-right-radius: 4px !important;
        }

        &:last-child {
          border-bottom: 1px solid #e0e0e0;
          border-bottom-left-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }

        .ant-collapse-header {
          padding: 12px 16px !important;

          .ant-collapse-header-text {
            color: #1a1a1a;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
          }
        }

        .preview-table {
          .header-table {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: #000;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;

            &.date {
              align-items: center;
            }

            span {
              color: #808080;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;

              &:first-child {
                font-size: 13px;
              }
            }
          }

          .bar {
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;

            span {
              color: #afafaf;
              text-align: center;
              font-size: 10px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              text-decoration-line: line-through;
            }
          }

          .ant-table-tbody {
            > tr {
              > td {
                padding: 19px 16px;

                &:not(:first-child) {
                  text-align: center;
                  padding: 7px 14px;
                  color: #1a1a1a;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;
                }

                &:first-child {
                  color: #2d2d2d;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: normal;

                  > div {
                    display: flex;
                    justify-content: space-between;
                  }
                }
              }

              &.bar-row > td {
                padding: 7px 0;

                &:first-child {
                  padding: 19px 12px 19px 16px;
                }
              }

              &.staycationrate-row > td {
                padding: 20px 0 11px;
                border-bottom: 0;

                &:first-child {
                  padding: 19px 12px 10px 16px;
                }
              }

              &.discount-row > td {
                padding: 11px 14px 20px;
                border-bottom: 0;

                &:first-child {
                  padding: 10px 16px 19px;
                }
              }
            }

            tr > td:not(:last-child) {
              border-right: 1px solid #f4f3f3;
            }

            tr:hover:not(.ant-table-expanded-row) > td {
              background-color: transparent;
            }

            tr:last-child > td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }
}
