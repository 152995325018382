@import 'src/styles/commons';

.headlines {
    display: grid;

    .headlines__add-button{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-self: center;
        border-radius: 4px;
        border: 1px dashed rgba(0, 0, 0, 0.15);
        width: 81px;
        height: 32px;
        font-weight: 500;
        margin-top: 24px;

        &:hover {
            border-color: #FF2E63;
        }

        .anticon svg {
            width: 14px;
            height: 14px;
            margin-right: 4px;
        }
    }
}
