@import 'src/styles/commons';

.editoEventContent {
    background-color: white;
}

.editoEventTabs .ant-tabs-nav {
    background-color: white;
    padding: 0px 24px 0px 24px;
    margin: 0px;
}

.editoEventTabs .ant-tabs-content-holder {
    padding: 24px 0px;
}
