.first-step {
    .width-104 {
        .ant-form-item-control-input, .ant-input-number {
            width: 104px;
        }
    }

    .width-200 {
        .ant-form-item-control-input, .ant-input-number {
            width: 200px;
        }
    }

    .width-320 {
        .ant-form-item-control-input, .ant-input-number {
            width: 320px;
        }
    }

    .width-440 {
        .ant-form-item-control-input, .ant-input-number {
            width: 440px;
        }
    }
}
