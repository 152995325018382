@import 'src/styles/commons';

.room-list {
    .header {
        .ant-page-header-heading-left {
            height: 32px;
        }

        .ant-page-header-heading-extra {
            margin: 0;

            .ant-btn {
                height: 32px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

    .body {
        .column-modal-checkbox {
            background-color: #F8F8F8;
            border: 0;
            font-weight: 500;

            &:hover {
                color: rgba(0, 0, 0, 0.85);
            }
            .ant-btn:hover {
                color: rgba(0, 0, 0, 0.85);
            }
        }
    }
}
