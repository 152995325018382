.multiple-dropdown .ant-picker-dropdown {
  top: 0 !important;
}

.multiple-dropdown .ant-picker-cell-selected .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.85);

  background: transparent;
}
.multiple-dropdown .ant-picker-cell-inner.dateSelected {
  position: relative;
  z-index: 2;
  width: 24px;
  height: 22px;
  line-height: 22px;
  background-color: rgb(255, 51, 102);
  color: rgb(255, 255, 255);
  margin: auto;
  border-radius: 4px;
  transition: background 0.3s ease 0s, border 0.3s ease 0s;
}

.multiple-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  content: none;
}

.date-tag-disabled {
  color: #bfbfbf;
  border-color: #d9d9d9;
}
