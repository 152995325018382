@import 'src/styles/commons';

.header-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.icon-btn {
  width: auto;
  padding: 8px;
}

