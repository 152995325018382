.tag-with-icon {
  display: inline-flex;
  padding: 2px 8px;
  align-items: center;
  gap: 6px;
  border-radius: 2px;
  background: #f9f9f9;
  color: #808080;
  border-radius: 2px;
  border: 1px solid #e5e5e5;

  &.success {
    border: 1px solid #d4edda;
    background: #f4fbf6;
    color: #28a745;
  }

  &.warning {
    border: 1px solid #ffeacc;
    background: #fffaf2;
    color: #ff9500;
  }

  &.error {
    border: 1px solid #FFD7D5;
    background: #FFF5F5;
    color: #FF382C;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}