@import 'src/styles/commons';

.informative-photo {
  .ant-row.ant-form-item-row {
    margin-bottom: 0;
  }

  .input-row {
    margin-bottom: 24px;

    .ant-row.ant-form-item-row {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0px;
    }

    .ant-row.ant-form-item-row {
      margin-bottom: 0;

      .upload-item {
        display: flex;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin: 6px;
        width: 114px;
        height: 114px;

        .upload-item__thumbnail {
          align-items: center;
          justify-content: center;
          display: flex;
          border-radius: 4px;
          margin: 8px;
          width: 96px;
          height: 96px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .ant-empty {
        height: 80px;

        .ant-empty-image {
          height: 52px;

          svg {
            height: 52px;
          }
        }
      }

      .ant-select {
        width: 320px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input {
        width: 440px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-input-number {
        width: 104px;
        border-color: rgba(0, 0, 0, 0.15);
      }

      .ant-form-item-label {
        margin-right: 16px;
        width: 192px;
        max-width: unset;

        label {
          font-weight: 500;
          font-size: 14px;
        }

        label.ant-form-item-required::before {
          display: none;
        }

        label::after {
          display: none;
        }
      }

      .view-text {
        padding-top: 4px;
        line-height: 24px;
        white-space: break-spaces;

        &-empty {
          color: rgb(191, 191, 191);
        }
      }
    }
  }

  .description {
    margin-left: 208px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
    vertical-align: middle;
    color: #8c8c8c;
    font-size: 14px;
    line-height: 20px;

    .anticon {
      margin: 3px 5px 3px 0;
    }
  }
}
