.badgeContainer {
  display: flex;
  align-items: center;
  justify-items: center;
  gap: 4px;
  padding: 4px;
  border-radius: 60px;

  .anticon svg {
    width: 10px;
    height: 10px;
  }
}

.badgeContainer .title {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
}
.title.bold {
  font-weight: 700;
}

.badgeContainer.success {
  background-color: #f4fbf6;
  color: #28a745;
}

.badgeContainer.danger {
  background-color: #fff5f5;
  color: #ff382c;
}

.badgeContainer.neutral {
  background-color: #f5f5f5;
  color: #8c8c8c;
}
