.package-tabs-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  z-index: 10;
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  .ant-tabs-nav {
    padding: 12px 24px 0;
    margin: 0;

    &::before {
      border-bottom: 1px solid #e5e5e5;
    }

    .ant-btn {
      color: #1a1a1a;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      padding-top: 4px !important;
    }

    .unpublished-package {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
  }
}
