.ant-descriptions {
    .ant-descriptions-item-label {
        width: 200px;
        font-size: 15px;
        font-weight: 500;

        &::after {
            content: '';
        }
    }

    .ant-descriptions-item-content {
        text-align: right;
        font-size: 15px;
    }
}
