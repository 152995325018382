.experienceTypeContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;

  .experienceTypeSubContainer {
    flex: 1;

    .staycationContainer {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
      position: relative;

      .multiNightLink {
        height: 87px;
        background-color: #d9d9d9;
        position: absolute;
        top: 45px;
        left: -12px;
        width: 1px;
      }

      .nightCountContainer {
        position: relative;
        width: 100%;
      }

      .datesMultiNightContainer {
        position: relative;
        width: 100%;
      }
    }
  }
}
