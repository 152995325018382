@import 'src/styles/commons';

.extranet-inventory__property-cell {
  &.extranet-inventory-automated-discount {
    flex-direction: column;
    padding-top: 10px;
    gap: 8px;
    line-height: normal;

    .automated-discount__toggle-modal-button {
      font-size: 11px;
      padding: 0;
      line-height: normal;
      height: fit-content;
      text-align: left;
      align-self: flex-start;
      gap: 4px;

      .anticon:not([disabled]) {
        font-size: 12px;
        color: $primary-color;
        margin: 0;
      }

      span {
        margin-left: 0;
      }
    }
    .property-cell--with-detail {
      &__content {
        margin-bottom: 0;
      }
    }
    .automated-discount__icon-container {
      .grey-icon {
        color: #999999;
      }
      .blue-icon {
        color: #0c3b7c;
      }
      .anticon {
        font-size: 12px;
      }
    }
  }
}
.ant-tooltip-inner {
  text-align: center;
}
