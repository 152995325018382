.actionContainer {
    padding: 24px; 
    background: #F6F6F6; 
    border-radius: 8px; 
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: 
    flex-start; 
    display: flex;
    position: relative;
    margin-top: 24px;

    .arrow {
        background: #F6F6F6;
        position: absolute;
        top: -16px; 
        border-radius: 2px;
        width: 36px; 
        height: 22px;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }

    .commonForm {
        display: flex;
        flex-direction: row;
        gap: 24px;
    }
}

