.discount-step {
  > div:not(:last-child) {
    padding: 0 16px;
  }

  .pricing-rule {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    > div {
      width: 100%;
    }
  }
}
