@import 'src/styles/commons';

.gallery-detail__layout {
    margin-left: 200px;
    background-color: #FFF;
    height: 100%;
    overflow-x: visible !important;

    .gallery-detail__header {
        top: 0;
        z-index: 5;
        background: #FFF;
        color: #FFF;
        border-bottom: #E0E0E0 solid 1px;
        width: 100%;
        padding: 16px 24px 0px 24px;
        position: sticky;

        .ant-menu-horizontal {
            border-bottom: 0px;
        }

        .ant-page-header-heading {
            height: 32px;
            margin: 0px;
        }

        .ant-page-header-heading-extra {
            margin: 0px;
        }

        .ant-page-header-content {
            padding-top: 0px;
        }
    }

    .gallery-detail__content {
        overflow: hidden;
        padding: 24px 24px 88px 24px;

        .div-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
        }
    }

    .gallery-detail__footer {
        display: flex;
        position: fixed;
        z-index: 5;
        bottom: 0;
        justify-content: space-between;
        background: #FFF;
        border-top: #E0E0E0 solid 1px;
        padding: 16px 24px;
        width: calc(100% - 200px) ;

        .ant-btn {
            padding: 0px 16px;
            height: 32px;
            line-height: 24px;
            font-weight: 500;

            &:hover:disabled {
                span {
                    color: rgba(0, 0, 0, 0.25);
                }
            }
        }

        .first-button {
            margin-right: 16px;
        }
    }
}

.gallery-detail__layout-block {
    display: block;
}
