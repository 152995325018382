.ant-select {
  width: 100%;

  .ant-select-selection-item {
    display: flex;
    align-items: center;

    .select-item__thumbnail {
      display: flex;
      margin-right: 10px;
      width: 24px;
      height: 24px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

//TODO: could be merged with sortable-item
.ant-select-item-option-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 30px;
}

.select-item__thumbnail {
  margin-right: 20px;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  overflow: hidden;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
  }
}
