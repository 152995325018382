@import 'src/styles/commons';


.ant-table {
    border: 1px solid $grey300;
    border-radius: 4px;

    .ant-table-title {
        padding: 16px;
    }

    .ant-table-row {
        background-color: #FFF;
        cursor: pointer;
    }

    .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
        background: $grey100;
    }
}
