.editoContainer {
    background: white; 
    border-radius: 8px; 
    border: 1px #D9D9D9 solid;  
    justify-content: flex-start; 
    align-items: flex-start;
}

.editoContainer > .titleContainer {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    border-bottom: 1px #D9D9D9 solid;

    .title {
        font-size: 20px; 
        font-weight: 600; 
        line-height: 28px; 
    }

    .subTitle {
        color: #8C8C8C; 
        font-size: 14px;
        font-weight: 400; 
        line-height: 22px;
    }
}

.editoContainer > .childrenContainer {
    padding: 24px;
}