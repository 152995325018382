.auto-inventory-explanation {
  display: flex;
  padding: 46px 32px 32px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  > div {
    width: 100%;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .images {
      display: flex;
      align-items: center;
      gap: 4px;

      .booking-icon,
      .staycation-icon {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid #e5e5e5;

        > * {
          width: 32px;
          height: 32px;
        }
      }
    }

    .icon {
      width: 56px;
      height: 56px;

      .anticon,
      .anticon svg {
        width: 100%;
        height: 100%;
      }
    }

    .title-subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      .head-title {
        color: #ff2e63;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
        text-transform: uppercase;
      }

      .title {
        color: #262626;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;

        .bold {
          font-weight: 700;
        }
      }

      .subtitle {
        color: #1a1a1a;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  .explanation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    .argument-list {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      padding: 16px;
      border-radius: 8px;
      background: #f9f9f9;
      color: #666;
      font-size: 14px;
      font-style: normal;
      line-height: 140%;
      width: 100%;

      > div:first-child {
        font-weight: 600;
      }

      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        color: #808080;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;

        > div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  .start-sync {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    button {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    p {
      color: #808080;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    button {
      display: flex;
      width: 181px;
      padding: 8px 16px;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }
}
