@import 'src/styles/commons';

.sheader .ant-page-header-heading-extra .ant-btn {
  font-size: 14px;
}

.delivery-content {
  background-color: $grey100;
}

.delivery-tabs .ant-tabs-nav {
  background-color: white;
  padding: 0;
  margin: 0;
}

.delivery-extra-content {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  .delivery-search-input {
    width: 204px;
  }
}

.deliveries-container {
  margin: 24px;
}

.tag-with-icon .text {
  font-weight: 400;
  line-height: 28px;
  font-size: 14px;
}

.ant-page-header-heading-left {
  overflow: visible;
}

.header-tag {
  margin-left: 16px;
}

.line-tag {
  font-size: 12;
  font-weight: 400;
  line-height: 20px;
  word-wrap: 'break-word';
  text-align: center;
}

.ant-table-tbody > tr > td {
  padding: 16px;
}

.ant-table-cell {
  .address-city {
    color: var(--color-gray-500, #808080);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}
