.entity {
  display: flex;
  align-items: center;
}

.entityThumbnail {
  width: 64px;
  height: 64px;

  img {
      border-radius: 4px;
      width: 64px;
      height: 64px;
      object-fit: cover;
  }
}

.entityName {
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  font-weight: 500;
}

.addButtonContainer {
  margin-top: 24px;
}
