.room-mapping-step {
  height: 100%;
  overflow-y: hidden;

  .room-mapping-association {
    padding: 0 16px;
    position: relative;
    height: 100%;
    flex-grow: 1;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .titles {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      gap: 64px;

      > div {
        color: #262626;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        flex: 1 0 0;
      }

      > div:first-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .reload-button {
          font-weight: 600;
        }
      }
    }

    .room-rows {
      width: 100%;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .room-row {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;

        > div:not(.divider) {
          flex: 1 0 0;
        }

        .divider {
          border-top-width: 2px;
          width: 48px;
          min-width: inherit;
        }

        .staycation-room {
          display: flex;
          padding: 8px;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          border-radius: 12px;
          border: 1px solid #f2f2f2;

          .room-image {
            border-radius: 4px;
            width: 56px;
            height: 40px;
          }

          .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1 0 0;
            overflow: hidden;
            color: #262626;
            text-overflow: ellipsis;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            display: block;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            max-height: 3em;
            /* autoprefixer: off */
          }
        }

        .booking-rooms.ant-select {
          flex: 1 0 0;
          overflow: hidden;

          .ant-select-selection-item {
            display: block;
          }
        }
      }
    }
  }
}
