@import 'src/styles/commons';

.header-wrapper {
    .header {
        &.ant-layout-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid $grey300;
            background: #FFF;
            width: calc(100% - 200px);
            height: 64px;
            position: fixed;
            z-index: 5;
            padding: 0 24px;

            h1 {
                font-size: 20px;
            }

            button {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
            }
        }
    }

    .body {
        margin-top: 64px;
        display: flex;
        flex-direction: row;
    }

    .form {
        flex-basis: 65%;
        flex-grow: 1;
        background: #FFF;
        padding: 40px 10% 80px 10%;
        overflow-y: scroll;
        height: 100%;

        .card {
            border: 0;
            padding: 0;
        }

        .section__title {
            margin-bottom: 24px;
            color: $grey700;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
